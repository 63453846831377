import React from "react";
import {FormattedMessage} from "react-intl";

export const Location = ({ content }) => {
  let address = content.fieldLocation?.entity?.fieldAddress;

  if (address) {
    return (
      <div className="location">
        <span className="name">
          {content.fieldLocation?.entity.name}
        </span>
        {(address.addressLine1 || address.addressLine2) &&
          <div className="address-line-wrapper">
            {address.addressLine1 &&
              <div className="line-1">
                {address.addressLine1}
              </div>
            }
            {address.addressLine2 &&
              <div className="line-2">
                {address.addressLine2}
              </div>
            }
          </div>
        }
        {address.locality &&
          <div className="locality-code">
            {address.postalCode &&
              <div className="code">
                {address.postalCode}
              </div>
            }
            <div className="locality">
              {address.locality}
            </div>
          </div>
        }
      </div>
    );
  }
};
