import React from "react";

export const Participants = (props) => {
  return (
    <div className="participants">
      {props.content.fieldParticipating && props.content.fieldParticipating.map((item, index) =>
        <div className="participant-wrapper" key={index}>
          {item.entity.fieldLabel &&
            <div className="label">
              {item.entity.fieldLabel}
            </div>
          }
          {item.entity.fieldParticipating &&
            <div className="participants" dangerouslySetInnerHTML={{ __html: item.entity.fieldParticipating.processed }} />
          }
        </div>
      )}
    </div>
  );
};
