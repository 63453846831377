import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Image from "../general-components/image/image";
import classNames from 'classnames';


const Content = (props) => {
  return(
    <>
      {props.content?.entity.fieldMedia.entity.fieldMediaImage &&
        <Image
          className="background-media"
          data={props.content.entity.fieldMedia.entity.fieldMediaImage}
        />
      }
      {props.content?.entity.fieldMedia.entity.fieldMediaVideoFile &&
        <video
          className="background-media"
          src={props.content.entity.fieldMedia.entity.fieldMediaVideoFile.entity.url}
          playsInline={true}
          loop={true}
          muted={true}
          autoPlay={true}
          controls={false}
        />
      }
      {props.content?.entity.fieldMenuCtaLink?.title &&
        <span className={`cta-info ${props.linkType}`}>
          {props.content.entity.fieldMenuCtaLink.title}
        </span>
      }
    </>
  );
}

const MenuCTA = ({ content }) => {
  const customClassNames = classNames({
    "cta-wrapper": true,
    "multiple": content.fieldMenuCtas?.length > 1
  });

  return (
    <div className={customClassNames}>
      {content.fieldMenuCtas && content.fieldMenuCtas.map((menuCta, index) => {
        // Looks weird, but if the config page is empty, drupal sends empty entities.
        if (menuCta && menuCta?.entity && menuCta.entity.fieldMenuCtaLink) {
          return (
            <article className={"menu-cta"} key={index}>
              {menuCta?.entity.fieldMenuCtaLink?.url.routed ? (
                <a href={menuCta.entity.fieldMenuCtaLink?.url.path}>
                  <Content content={menuCta} linkType={"external-link"}/>
                </a>
              ) : (
                <Link to={menuCta.entity.fieldMenuCtaLink?.url.path}>
                  <Content content={menuCta} linkType={"internal-link"}/>
                </Link>
              )}
            </article>
          )
        }
      })}
    </div>
  );
};

MenuCTA.propTypes = {
  content: PropTypes.object.isRequired
};

export default MenuCTA;