import { gql } from '@apollo/client';

// Used to get "conditional conditions" which are not supported by GraphQLSearchApi.

export const searchQuery = gql`
  query searchQuery(
    $searchString: String!,
    $conditions: [ConditionInput!]!
  ) {
    searchAPISearch(
      index_id: "searchindex_hmdk",
      range: { offset: 0, limit: 200 },
      fulltext: { keys: [$searchString] },
      condition_group: {
        groups: [
          {
            conjunction: OR,
            conditions: $conditions
          }
        ]
      },
      sort: [{ field: "search_api_relevance", value: "DESC" }]
    ) {
      result_count
      documents {
        ... on  SearchindexHmdkDoc {
          title
          url
          status
          field_site_channel
          type
          image_1
          image_2
          image_3
          image_4
          field_internal
        }
      }
    }
  }
`;

export const buildConditions = (siteChannel, contentType, isKV, isHMDK) => {
  const conditions = [
    { operator: "IN", name: "field_site_channel", value: siteChannel },
    { operator: "IN", name: "type", value: contentType }
  ];

  // Add "Artist", "Ensemble" and "KV_Landingpage" Content Type to searchable Content
  if (isKV) {
    conditions.push({ operator: "IN", name: "type", value: "artist" });
    conditions.push({ operator: "IN", name: "type", value: "ensemble" });
  }

  // Add "Course" Content Type to searchable Content
  if (isHMDK) {
    conditions.push({ operator: "IN", name: "type", value: "course" });
  }

  return conditions;
};