import React from "react";
import { useSelector } from "react-redux";
import {Redirect, Route} from "react-router-dom";

const Error404 = () => {
  const microSite = useSelector(reduxStore => reduxStore.appStore.microSite);

  let notFoundUrl = "";

  if (microSite === "kv") {
    notFoundUrl = "/404-kv";
  } else if (microSite === "wt") {
    notFoundUrl = "/404-wt";
  } else if (microSite === "gdf") {
    notFoundUrl = "/404-gdf";
  } else {
    notFoundUrl = "/404";
  }

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return (
          <Redirect to={notFoundUrl} />
        );
      }}
    />
  );
};

export default Error404;