import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import {self} from '../../../../config';
import GalleryArrow from "../../../icons/gallery-arrow";
import CustomHyphenation from "../../../custom-hyphenation";

class ParagraphNumberFacts extends Component {
  numberFacts = React.createRef();

  render() {
    const slidesToShowDesktop = 3.33,
      slidesToShowTablet = 2,
      slidesToShowMobile = 1.33;

    const sectionClassNames = classNames({
      "paragraph paragraph-number-facts": true,
    }),
    sliderSettings = {
      arrows: true,
      nextArrow: <GalleryArrow type={"next"} followMouse={false}/>,
      prevArrow: <GalleryArrow type={"prev"} followMouse={false}/>,
      dots: false,
      slidesToShow: slidesToShowDesktop,
      slidesToScroll: 1,
      infinite: true,
      centerMode: false,
      touchMove: true,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: slidesToShowDesktop,
            variableWidth: true,
            centerMode: true
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: slidesToShowTablet
          },
        },
        {
          breakpoint: 476,
          settings: {
            slidesToShow: slidesToShowMobile
          },
        }
      ],
    };

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
          </div>
        </div>
        <div className={"number-fact-wrapper"} ref={this.numberFacts}>
          <Slider {...sliderSettings}>
            {this.props.content.fieldZahlenUndFakten?.map((item, index) => {
              const numberFactClassNames = classNames({
                  "number-fact": true,
                  "download": item.entity.fieldDownload,
                  "fact": !item.entity.fieldDownload,
                  "download-before": this.props.content.fieldZahlenUndFakten[index - 1]?.entity?.fieldDownload,
                  "less-space": item.entity.fieldZahl.length < 120 && item.entity.fieldZahl.length > 80,
                  "long-text": item.entity.fieldZahl.length >= 120,
                  "normal": item.entity.fieldZahl.length < 1200,
                });

              return (
                <div
                  className={numberFactClassNames}
                  key={index}
                >
                  <div className="inner-wrapper">
                    <div className="number">
                      <CustomHyphenation length={8}>
                        {item.entity.fieldZahl}
                      </CustomHyphenation>
                      {item.entity.fieldDownload &&
                        <a
                          href={item.entity.fieldDownload.entity.fieldMediaFile.entity.url}
                          download={true}
                          target={"_blank"}
                        >
                      <span className="sr-only">
                        download
                      </span>
                          <img src={`${self}/download.svg`} className="download-icon" alt={"download icon"}/>
                        </a>
                      }
                    </div>
                    <div className="label">
                      <CustomHyphenation length={8}>
                        {item.entity.fieldLabel}
                      </CustomHyphenation>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    );
  }
}

ParagraphNumberFacts.propTypes = {
  content: PropTypes.shape({
    fieldZahlenUndFakten: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldZahl: PropTypes.string.isRequired,
          fieldLabel: PropTypes.string.isRequired,
        }),
      })
    ),
  }),
};

export default ParagraphNumberFacts;
