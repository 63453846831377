import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import PeopleLink from "./people-link";
import {useSelector} from "react-redux";

const ShortcutMenu = ({
  links,
  config,
  ...props
}) => {

  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);

  return (
    <nav className="col shortcut-menu">
      {
        <ul>
          {links && links.map((item, index) => (
              <React.Fragment key={index}>
                {/* Check if link is a real link, or just a label for a deeper link tree */}
                {item.url.path === "" ? (
                  <li onClick={() => props.shortcutToMenuLink(item.label)}>
                    <span>
                      {item.label}
                    </span>
                  </li>
                ) : (
                  <li>
                    <Link to={item.url.path}>
                      {item.label}
                    </Link>
                  </li>
                )}
              </React.Fragment>
            ))}
          {!microSite &&
            <PeopleLink
              config={config}
            />
          }
        </ul>
      }
    </nav>
  );
};

ShortcutMenu.propTypes = {
  links: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired
};

export default ShortcutMenu;