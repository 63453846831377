import React from "react";

const Download = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.97"
      height="42"
      fill="none"
      version="1.1"
      viewBox="0 0 35 42"
      className="download-icon"
    >
      <path fill="none" d="M-15.515 -11H48.485V53H-15.515z" vector-effect="non-scaling-stroke"></path>
      <path stroke="#000" strokeWidth="2" d="M0 41h32.97" vector-effect="non-scaling-stroke"></path>
      <path stroke="#000" strokeWidth="2" d="M16.485 0v32" vector-effect="non-scaling-stroke"></path>
      <path
        stroke="#000"
        strokeWidth="2"
        d="M1.94 17l14.545 14.5L30.545 17"
        vector-effect="non-scaling-stroke"
      ></path>
    </svg>
  );
}

export default Download;