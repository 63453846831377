import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastNotification } from "carbon-components-react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import { authenticationLoginAction } from "./redux-authentication";

const mapStateToProps = (reduxStore) => ({ auth: reduxStore.auth });

class UserStatusBlock extends Component {
  state = {
    error: "",
  };

  logout = () => {
    axios
      .post(
        `/api/v1/logout`,
        {
          access_token: this.props.auth.userLoginData.access_token,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      )
      .then(() => {
        this.props.dispatch(authenticationLoginAction({}));
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.message,
        });

        this.props.dispatch(authenticationLoginAction({}));
      });
  };

  render() {

    return (
      <li
        className="logout"
      >
        <a
          className="btn"
          onClick={() => this.logout()}
          href="#"
        >
          Logout
        </a>
      </li>
    );
  }
}

UserStatusBlock.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(UserStatusBlock);
