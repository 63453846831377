import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import Image from "../../image/image";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";
import { FormattedMessage } from "react-intl";
import PersonImage from "../../../person/person-image";
import PersonName from "../../../person/person-name";
import PersonClassification from "../../../person/person-classification";
import {VcardSaveButton} from "../../../person/vcard-save-button";
import WriteMail from "../../write-mail/write-mail";

const TeaserPersonKontakt = (props) => {
  const { item } = props;
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp); // replacement for mapStateToProps

  return (
    <article className="node node-teaser teaser-person teaser-person-kontakt">
      <EditButton
        adminApp={adminApp}
        entityId={props.item.entityId}
        destinationRoute={props.location.pathname}
      />
      <Link
        className="flex-wrap"
        to={
          item.entityUrl
            ? item.entityUrl.path
            : item.path.alias
        }
      >
        {props.showImage && (
          <Image
            data={item.fieldBildWCaption.entity.fieldMediaImage}
            nodeTitle={item.title}
          />
        )}
        <PersonImage content={props.item}/>
        <div className="text-wrapper">

          <PersonClassification content={props.item}/>

          <PersonName content={props.item}/>

        </div>
      </Link>
      <div className="contact-wrapper">
        {item.fieldTelefon && (
          <div className="flex-wrap contact">
              <span className="label">
                <FormattedMessage id="contact.phone" />
              </span>
            <a
              className="phone"
              href={`tel:${item.fieldTelefon
                .replace(/ /g, "")
                .replace(/\([\s\S]*?\)/g, "")}`}
            >
              {item.fieldTelefon}
            </a>
          </div>
        )}
        {item.fieldMobil && (
          <div className="flex-wrap">
            <span className="label">
              <FormattedMessage id="contact.mobile" />
            </span>
            <a
              className="mobil"
              href={`tel:${item.fieldMobil
                .replace(/ /g, "")
                .replace(/\([\s\S]*?\)/g, "")}`}
            >
              {item.fieldMobil}
            </a>
          </div>
        )}
        {item.fieldFax && (
          <div className="flex-wrap">
            <span className="label">
              <FormattedMessage id="contact.fax" />
            </span>
            <a
              className="fax"
              href={`tel:${item.fieldFax
                .replace(/ /g, "")
                .replace(/\([\s\S]*?\)/g, "")}`}
            >
              {item.fieldFax}
            </a>
          </div>
        )}

        <WriteMail content={item}/>

        {item.fieldRoom && (
          <div className="room-wrapper flex-wrap">
              <span className="label">
                <FormattedMessage id="contact.room" />
              </span>
            <span className="room">
                {item.fieldRoom}
              </span>
          </div>
        )}

        <div className="contact">
          <VcardSaveButton content={item} />
        </div>
      </div>
    </article>
  );
};

TeaserPersonKontakt.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
  showImage: PropTypes.bool.isRequired,
};

export default withRouter(TeaserPersonKontakt);
