import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Image from "../../../image/image";
import PropTypes from "prop-types";
import ParagraphNumberFacts from "../number-facts/paragraph-number-facts";

export const TeaserContent = (props) => {
  return(
    <div className="multi-teaser">
      {props.content.image &&
        <Image data={props.content.image} />
      }
      {props.content.tags &&
        <div className="tags">
          {props.content.tags.map(
            (tag, index) => (
              <div className="tag" key={index}>
                {tag}
              </div>
            )
          )}
        </div>
      }
      <h3>{props.content.title}</h3>
    </div>
  );
};


export const MultiTeaser = (props) => {
  let content = {
    title: false,
    link: false,
    linkRouted: false,
    tags: false,
    image: false
  }

  if (props.content?.entity?.__typename === "ParagraphContentTeaser" ) {
    content = {
      title: props.alternativeLabel ? props.alternativeLabel : props.content.entity.fieldTeaserTitle,
      link: props.content.entity.fieldReferencedInternalConten && props.content.entity.fieldReferencedInternalConten.entity && props.content.entity.fieldReferencedInternalConten.entity.entityUrl.path,
      linkRouted: true,
      tags: false,
      image: props.content.entity.fieldReferencedInternalConten.entity.fieldTeaserbild ? props.content.entity.fieldReferencedInternalConten.entity.fieldTeaserbild?.entity.fieldMediaImage : props.content.entity.fieldReferencedInternalConten.entity.fieldBild ? props.content.entity.fieldReferencedInternalConten.entity.fieldBild?.entity.fieldMediaImage : false
    }
  }

  if (props.content?.entity?.__typename === "ParagraphLinkTeaser" ) {
    content = {
      title: props.content.entity.fieldTeaserTitle,
      link: props.content.entity.fieldTeaserLink && props.content.entity.fieldTeaserLink.url.path,
      linkRouted: props.content.entity.fieldTeaserLink ? props.content.entity.fieldTeaserLink.url.routed : true,
      tags: props.content.entity.fieldTags,
      image: props.content.entity.fieldImage?.entity.fieldMediaImage
    }
  }

  if (props.content.entityBundle === "artist") {
    content = props.content;
  }

  return(
    <article className="col-16 col-sm-8 teaser-col">
      {content.linkRouted ? (
        <Link to={content.link}>
          <TeaserContent content={content}/>
        </Link>
      ) : (
        <a href={content.link} target={"_blank"}>
          <TeaserContent content={content}/>
        </a>
      )}
    </article>
  );
};

MultiTeaser.propTypes = {
  content: PropTypes.shape({
    image: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    title: PropTypes.string,
    link: PropTypes.string,
    linkRouted: PropTypes.bool,
    entityBundle: PropTypes.string,
    tags: PropTypes.array,
  }),
};
export default MultiTeaser;
