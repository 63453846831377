import React from "react";
import classNames from "classnames";
import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

export const LogoPartner = (props) => {

  return(
    <div
      key={props.key}
      className={classNames({
        partner: true,
        "col-16 col-md-8 col-lg-4": !props.marquee
      })}
    >
      {props.content.entity.fieldLink !== null ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={props.content.entity.fieldLink.url.path}
        >
          <Image data={props.content.entity.fieldMediaImage1} />
        </a>
      ) : (
        <ErrorBoundary>
          <Image data={props.content.entity.fieldMediaImage1} />
        </ErrorBoundary>
      )}
    </div>
  );
};
