import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";

import ComponentBildSlider from "./component-bild-slider";
import ErrorBoundary from "../../../../error-boundary";

const Caption = ({ content }) => {

  if (content.fieldSingleImage.entity.fieldBildunterschrift ||
    content.fieldSingleImage.entity.fieldCredit ||
    content.fieldSingleImage.customCaption) {
    return (
      <div className="caption-wrap">
        {content.fieldSingleImage.customCaption && (
          <span className="caption">{content.fieldSingleImage.customCaption}</span>
        )}
        {content.fieldSingleImage.entity.fieldBildunterschrift && !content.fieldSingleImage.customCaption && (
          <span className="caption">{content.fieldSingleImage.entity.fieldBildunterschrift}</span>
        )}
        {content.fieldSingleImage.entity.fieldCredit && (
          <span className="credit">Foto: {content.fieldSingleImage.entity.fieldCredit}</span>
        )}
      </div>
    );
  }
}

class ParagraphBilderSlider extends Component {

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-media": true,
      "slider": this.props.content.fieldMultimedia,
      "single-image": this.props.content.fieldSingleImage,
      [`format-${this.props.content.fieldFormat}`]: true,
    }),
    wrapperClassNames = classNames({
      "col-16 col-lg-10 offset-lg-3": this.props.formatSmall && this.props.viewMode !== "collapse",
      "col-13 offset-lg-1 col-lg-10 offset-lg-3": !this.props.formatSmall && this.props.viewMode !== "collapse",
      "col-16 col-md-12 offset-md-2": this.props.viewMode === "collapse"
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldFormat === "full" || !this.props.content.fieldSingleImage ? (
          <ErrorBoundary>
            {!this.props.content.fieldSingleImage ? (
              <>
                {(this.props.content.fieldSmall || this.props.content.fieldMultimedia.length <= 4 || this.props.viewMode === "collapse") ? (
                  <div className="container">
                    <div className="row">
                      <div className={wrapperClassNames}>
                        <ComponentBildSlider
                          wrapCaption={true}
                          content={this.props.content}
                          globalCaption={this.props.content.fieldBildunterschrift}
                          formatSmall={true}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <ComponentBildSlider
                    wrapCaption={true}
                    content={this.props.content}
                    globalCaption={this.props.content.fieldBildunterschrift}
                    formatSmall={false}
                  />
                )}
              </>
            ) : (
              <ComponentBildSlider
                wrapCaption={true}
                content={this.props.content}
                globalCaption={this.props.content.fieldBildunterschrift}
                formatSmall={false}
              />
            )}
          </ErrorBoundary>
        ) : (
          <div className="container">
            <div className="row">
              {this.props.content.fieldSingleImage && (this.props.content.fieldFormat === "right" || this.props.content.fieldFormat === "left") &&
                <div
                  className={classNames({
                    "col-16 caption-col": true,
                    "col-lg-8": this.props.content.fieldFormat === "right",
                    "col-lg-7 order-1": this.props.content.fieldFormat === "left"
                  })}
                >
                  <Caption content={this.props.content}/>
                </div>
              }
              <div
                className={classNames({
                  "col-16": true,
                  "col-lg-8 offset-lg-4":
                    this.props.content.fieldFormat === "center",
                  "col-lg-8 offset-lg-1": this.props.content.fieldFormat === "left",
                  "col-lg-8":
                    this.props.content.fieldFormat === "right",
                })}
              >
                <ErrorBoundary>
                  <ComponentBildSlider
                    content={this.props.content}
                    hideCaption={this.props.content.fieldFormat !== "left" || this.props.content.fieldFormat !== "right"}
                    globalCaption={this.props.content.fieldBildunterschrift}
                    formatSmall={false}
                  />
                  {this.props.content.fieldFormat === "center" &&
                    <Caption content={this.props.content}/>
                  }
                </ErrorBoundary>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldMultimedia: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        customCaption: PropTypes.string,
        defaulrCaption: PropTypes.bool,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          fieldBildunterschrift: PropTypes.string,
          fieldCredit: PropTypes.string,
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            format4To3: PropTypes.shape({
              __typename: PropTypes.string,
              url: PropTypes.string,
            }),
            style: PropTypes.shape({
              height: PropTypes.number,
              width: PropTypes.number,
              __typename: PropTypes.string,
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
      })
    ),
    fieldBilder: PropTypes.array,
    __typename: PropTypes.string,
    fieldColorScheme: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        fieldColorVariable: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    entityId: PropTypes.string,
    entityBundle: PropTypes.string,
    fieldSingleImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
    fieldSmall: PropTypes.bool,
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
  }),
};

export default ParagraphBilderSlider;
