import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import HeadlineSelectableSize from "../../../headline-selectable-size";

class ParagraphPageTitle extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-page-title": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <HeadlineSelectableSize
                content={this.props.title}
                size={"h1"}
                customClassNames={"title"}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphPageTitle.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string
  }),
  title: PropTypes.string
};

export default ParagraphPageTitle;
