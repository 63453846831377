import React from "react";
import FieldTime from "../general-components/date-time/field-time";
import moment from "moment";

export const EventDate = (props) => {

  let content = props.content?.entity || props.content;
  let eventPassed = moment().diff(moment.unix(content.fieldDate?.value).local()) > 0,
    endValueSameDay = content.fieldDate.endValue && moment.unix(content.fieldDate.endValue).local().format("DD.MM.YYYY") === moment.unix(content.fieldDate.value).local().format("DD.MM.YYYY"),
    showEndValue = content.fieldDate.endValue && moment.unix(content.fieldDate.endValue).local().format("HH:mm") !== "00:00" && moment.unix(content.fieldDate.endValue).local().format("HH:mm") !== "23:59" && endValueSameDay && moment.unix(content.fieldDate.value).local().format("HH:mm") !== moment.unix(content.fieldDate.endValue).local().format("HH:mm");

  if (content) {
    return(
      <>
        {content.fieldDate && content.fieldDate.value &&
          <>
            <FieldTime
              date={content.fieldDate.value}
              format={`DD.MM${content.fieldDate.endValue ? '.' : '.YYYY'}${eventPassed && endValueSameDay ? 'YYYY ' : ''}`}
              timestamp={true}
            />
            {content.fieldDate && moment.unix(content.fieldDate.value).local().format("HH:mm") !== "00:00" &&
              <FieldTime
                date={content.fieldDate.value}
                format={" HH:mm "}
                timestamp={true}
              />
            }
          </>
        }
        {content.fieldDate && content.fieldDate.endValue && showEndValue &&
          <>
            –
            {!endValueSameDay &&
              <FieldTime
                date={content.fieldDate.endValue}
                format={`DD.MM.${eventPassed ? 'YYYY ' : ''}`}
                timestamp={true}
              />
            }
            {content.fieldDate && showEndValue &&
              <FieldTime
                date={content.fieldDate.endValue}
                format={" HH:mm"}
                timestamp={true}
              />
            }
          </>
        }
      </>
    );
  }
};

export default EventDate;