import PropTypes from "prop-types";
import React from "react";
import {self} from "../config";


const MenuButton = (props) => {
  return(
    <button
      tabIndex={0}
      className={`toggle-fullscreen-menu close-menu ${props.menuOpen ? "active" : ""} ${props.closeOnly ? 'standard' : 'close-only'}`}
      aria-label="toggle-full-screen-menu"
      onClick={() => props.onClick()}
      onKeyDown={() => props.onKeyDown()}
    >
      <div className="bar"/>
      <div className="bar"/>
    </button>
  );
}

MenuButton.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  closeOnly: PropTypes.bool.isRequired
};

export default MenuButton;