const restHost = 'https://hmdk-cs.dev.e-fork.net',
  restHostBackend = 'https://hmdk-cs.dev.e-fork.net',
  graphQlEndpoint = '/graphql',
  drupalFormat = 'YYYY-MM-DDTHH:mm:ss',
  self = 'https://hmdk-vs.dev.e-fork.net/';

const configIDs = {
  "hmdk": "1",
  "general": "6"
}

export { restHost, restHostBackend, graphQlEndpoint, self, drupalFormat, configIDs };
