import React from "react";
import {FormattedMessage} from "react-intl";

const LectureHeader = () => {

  return (
    <table className={"lecture-header col-16"}>
      <thead>
        <tr className="row">
          <th className="title col-16 col-md-6">
            <FormattedMessage id={"lectureheader.title"}/>
          </th>

          <th className="day col-16 col-md-2">
            <FormattedMessage id={"lectureheader.day"}/>
          </th>

          <th className="time col-16 col-md-2">
            <FormattedMessage id={"lectureheader.time"}/>
          </th>

          <th className="location col-16 col-md-2">
            <FormattedMessage id={"lectureheader.location"}/>
          </th>

          <th className="lp col-16 col-md-1">
            <FormattedMessage id={"lectureheader.lp"}/>
          </th>

          <th className="lecturer col-16 col-md-3">
            <FormattedMessage id={"lectureheader.lecturer"}/>
          </th>
        </tr>
      </thead>
    </table>
  );
}

export default LectureHeader;