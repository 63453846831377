import React, { useState, useEffect } from "react";
import moment from "moment";
import {FormattedMessage} from "react-intl";
import axios from "axios";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

export const ParagraphBite = (props) => {

  const [jobs, setJobs] = useState(false),
    mainSettings = useSelector(reduxStore => reduxStore.appStore.mainSettings);

  useEffect(() => {
    axios
      .post(
        `https://jobs.b-ite.com/api/v1/postings/search`,
        JSON.stringify({
          method: "POST",
          key: "76c25c79a29d6feb407ccecf3e678335c8e71764",
          channel: 0,
          locale: "de",
          sort: {
            "by": "title",
            "order": "asc"
          },
          page: {
            "offset": 0
          }
        }),
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response?.data?.jobPostings !== jobs) {
          setJobs(response?.data?.jobPostings);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return(
    <section className="paragraph paragraph-bite">
      <div className="container">
        <div className="row">
          <div className="col-16 col-lg-12 offset-lg-2">
            {props.content.fieldHeading &&
              <h3>
                {props.content.fieldHeading}
              </h3>
            }
            {jobs && jobs.length > 0 ? (
              <>
                {jobs.map((jobPosting, index) =>
                  <article className="job-posting" key={index}>
                    <a
                      href={jobPosting.url}
                      target="_blank"
                    >
                      {jobPosting.title &&
                        <h4>
                          {jobPosting.title}
                        </h4>
                      }
                      {jobPosting.endsOn &&
                        <span className="ends-on">
                          <FormattedMessage id="job.ends_on" /> {moment(jobPosting.endsOn).format("DD.MM.YYYY")}
                        </span>
                      }
                    </a>
                  </article>
                )}
              </>
            ) : (
              <div className="no-results">
                {mainSettings?.fieldBiteTextNoResults &&
                  <div className="text"
                     dangerouslySetInnerHTML={{ __html: mainSettings.fieldBiteTextNoResults.processed }}
                  />
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphBite.PropTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldHeading: PropTypes.string,
  })
}

export default ParagraphBite;