import React, { Component, useEffect, useState } from "react";
import { MorphReplace } from 'react-svg-morph';
import {useThrottle, useThrottleCallback} from '@react-hook/throttle'

const HoveredArrow = () => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      fill="none"
      viewBox="0 0 66 66"
    >
      <path
        d="M33 0C14.77 0 0 14.77 0 33s14.77 33 33 33 33-14.77 33-33S51.23 0 33 0zm19.69 33.73L34.78 50.65s-.12.04-.17 0l-1.21-1.28s-.04-.12 0-.17l4.21-3.97c4.27-4.04 1.42-11.22-4.46-11.22H14.12c-.07 0-.12-.05-.12-.12v-1.76c0-.07.05-.12.12-.12h18.16c5.97 0 8.78-7.38 4.32-11.35l-3.17-2.82s-.05-.12 0-.17l1.17-1.32s.12-.05.17 0l17.91 15.92.82.73-.79.75z"
        className="cls-1"
      ></path>
    </svg>
  );
}
const Arrow = () => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      fill="none"
      version="1.1"
      viewBox="0 0 66 66"
    >
      <path
        d="M33 0C14.77 0 0 14.77 0 33s14.77 33 33 33 33-14.77 33-33S51.23 0 33 0zm19.69 33.73L34.78 50.65s-.12.04-.17 0l-1.21-1.28s-.04-.12 0-.17l15.87-14.99c.08-.07.03-.2-.08-.2H14.12c-.07 0-.12-.05-.12-.12v-1.76c0-.07.05-.12.12-.12h34.94c.11 0 .16-.13.08-.21L33.43 17.83s-.05-.12 0-.17l1.17-1.32s.12-.05.17 0l17.91 15.92.82.73-.79.75z"
        className="cls-1"
      ></path>
    </svg>
  );
}

const GalleryArrow = (props, parentRef) => {
  const { className, onClick, type } = props;

  const [mouseLocalCoordinates, setMouseLocalCoordinates] = useState({x:undefined, y:undefined, toLeft: undefined}),
    [mouseLeft, setMouseLeft] = useState(true),
    [hovering, setHovering] = useThrottle(false, 15, false);

  const mouseMoveHandler = (event) => {
    setMouseLeft(false);
    setMouseLocalCoordinates({
      x: `${event.clientX}px`,
      y: `${event.clientY - props.parentRef?.current.getBoundingClientRect().top}px`,
      transform: event.clientX < (window.innerWidth / 2) ? `rotate(180deg) ${mouseLeft ? 'scale(0)' : 'scale(1)'}` : `rotate(0deg) ${mouseLeft ? 'scale(0)' : 'scale(1)'}`
    });
  }

  const mouseEnter = () => {
    setHovering(true);
  }

  const mouseLeave = () => {
    setTimeout(() => {
      if (hovering) {
        setHovering(false);
      }
    }, 500);
  }

  const mouseFollowLeaveHandler = () => {
    setMouseLeft(true);
  }

  useEffect(()=>{
    if (props.followMouse) {
      props.parentRef?.current.addEventListener('mousemove', mouseMoveHandler);
      props.parentRef?.current.addEventListener("mouseleave", mouseFollowLeaveHandler);
      return(()=>{
        props.parentRef?.current.removeEventListener('mousemove', mouseMoveHandler);
        props.parentRef?.current.removeEventListener('mouseleave', mouseFollowLeaveHandler);
      })
    }
  }, [])

  {/*
  * Anderer Ansatz: https://tomdohnal.com/posts/react-svg-animation-with-react-spring-4
  */}

  return (
    <div
      className={`arrow-wrapper gallery ${type} ${props.followMouse ? 'follow-mouse' : 'standard'} ${className}`}
      data-mouse-left={mouseLeft}
      onClick={onClick}
      onMouseOver={mouseEnter}
      onMouseOut={mouseLeave}
      style={{
        left: mouseLocalCoordinates.x,
        top: mouseLocalCoordinates.y,
        transform: mouseLocalCoordinates.transform
      }}
    >
      <MorphReplace width={66} height={66} rotation={"none"} duration={250}>
        {hovering ? <HoveredArrow key="hovering" /> : <Arrow key="standard" />}
      </MorphReplace>
    </div>
  );
}

export default GalleryArrow;
