import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";


const PeopleLink = ({
  config,
  onClick,
  ...props
}) => {

  if (config?.fieldPeopleLink) {
    return (
      <li
        className="people-link"
        onClick={onClick}
      >
        <Link to={config.fieldPeopleLink?.url.path}>
          {config.fieldPeopleLink?.title}
          {config?.fieldPeople &&
            <div className="people-animation">
              <div className={"animation-wrapper"}>
                {config.fieldPeople.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.entity.fieldBildWCaption?.entity?.fieldMediaImage?.style.url &&
                      <img
                        src={item.entity.fieldBildWCaption?.entity?.fieldMediaImage?.style.url}
                        alt={item.entity.entityLabel}
                      />
                    }
                  </React.Fragment>
                ))}
              </div>
            </div>
          }
        </Link>
      </li>
    );
  }
};

PeopleLink.propTypes = {
  config: PropTypes.object
};

export default PeopleLink;