import React, { Component } from "react";
import PropTypes from "prop-types";
import { Close32 } from "@carbon/icons-react";

import Image from "../../image/image";
import { teaserPersonPropTypes } from "./teaser-person";
import ContentBase from "../../content-base/content-base";
import { FormattedMessage } from "react-intl";

const TeaserPersonOverlay = (props) => {
  return (
    <article className="node node-teaser teaser-person teaser-overlay">
      <div className="row">
        <div className="col-16 col-md-7 main-col theme-1">
          {props.item?.fieldBildWCaption &&
            <Image
              data={props.item?.fieldBildWCaption.entity.fieldMediaImage}
              style="styleSquare"
              nodeTitle={props.item?.title}
              credit={props.item?.fieldBildWCaption.entity.fieldCredit}
              caption={
                props.item?.fieldBildWCaption.defaultCaption === false
                  ? props.item?.fieldBildWCaption.customCaption
                  : null
              }
            />
          }
          <h1>{props.item?.entityLabel}</h1>
          <div className="person-details">
            {props.item?.fieldRolleOderFunktion.map((item, index) => (
              <React.Fragment key={index}>
                {item.entity && (
                  <p>
                    {item.entity.name}
                    {index !== props.item?.fieldRolleOderFunktion.length - 1 &&
                      index < props.item?.fieldRolleOderFunktion.length &&
                      ", "}
                  </p>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="col-16 col-md-9 sidebar">
          {props.toggleOverlay && (
            <div className="close" onClick={() => props.toggleOverlay()}>
              <Close32 />
            </div>
          )}

          <h3 className="headline-s">Kontakt</h3>

          <div className="row infos">
            <div className="col-8">
              <div className="contact-details-wrapper">
                {props.item?.fieldMail && (
                  <div className="label margin-bottom">
                    <a href={`mailto:${props.item?.fieldMail}`}>
                      <FormattedMessage id="contact.write_email" />
                    </a>
                  </div>
                )}
              </div>
              <div className="contact-details-wrapper">
                {props.item?.fieldTelefon && (
                  <div className="flex-wrap">
                    <span>Telefon</span>
                    <span>{props.item?.fieldTelefon}</span>
                  </div>
                )}
                {props.item?.fieldMobil && (
                  <div className="flex-wrap">
                    <span>Mobil</span>
                    <span>{props.item?.fieldMobil}</span>
                  </div>
                )}
              </div>

              <div className="contact-details-wrapper">...</div>

              <div className="contact-details-wrapper">
                {props.item?.fieldMail && (
                  <a
                    className="btn btn-s btn-save"
                    href={`mailto:${props.item?.fieldMail}`}
                  >
                    <FormattedMessage id="contact.save_contact" />
                  </a>
                )}
              </div>
            </div>
          </div>

          {props.item?.fieldKontakinformationen.length > 0 && (
            <div className="contact-infos infos">
              {props.item?.fieldKontakinformationen.map((item, index) => (
                <div className="row" key={index}>
                  <div className="col-8">
                    <div className="label">{item.entity.fieldContactType}</div>
                  </div>
                  <div className="col-8">
                    <div
                      className="value"
                      dangerouslySetInnerHTML={{
                        __html: item.entity.fieldWert.processed,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="modules-wrapper">
            {props.item?.fieldModules && (
              <ContentBase content={props.item?.fieldModules} />
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

TeaserPersonOverlay.propTypes = {
  item: teaserPersonPropTypes,
  toggleOverlay: PropTypes.func,
};

export default TeaserPersonOverlay;
