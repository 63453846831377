import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";

// @todo defaultValue is not working for WebformElementNumber
const NumberField = ({ item, compositeIndex, compositeParent }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item.states,
    values
  );

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item.flex ? { flex: item.flex } : {}}
    >
      <label htmlFor={item.id}>
        {item.title}
        {(!!item.required || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      <Field
        id={
          compositeParent
            ? `${compositeParent}[${compositeIndex}][${item.id}]`
            : item.id
        }
        name={
          compositeParent
            ? `${compositeParent}[${compositeIndex}][${item.id}]`
            : item.id
        }
        type="number"
        min={item.min}
        max={item.max}
        step={item.step}
        className="form-control"
        onChange={handleChange}
        onBlur={handleBlur}
        value={
          compositeParent
            ? values[compositeParent][compositeIndex][item.id]
            : values[item.id]
        }
        required={(!!item.required || required) && !optional && visible}
        disabled={!enabled || disabled}
      />
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
      {!!item.description && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
    </div>
  );
};

NumberField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    states: PropTypes.array,
    description: PropTypes.string,
    flex: PropTypes.number,
    required: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
  }),
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default NumberField;
