import React from "react";
import CustomHyphenation from "../general-components/custom-hyphenation";

export const PersonClassification = ({ content }) => {
  return(
    <div className="classification">
      {content?.fieldPositionFree &&
        <span className="info free">
          <CustomHyphenation length={8}>
            {content.fieldPositionFree}
          </CustomHyphenation>
        </span>
      }
      {content?.fieldSubject &&
        <div className={"multiple-values subjects"}>
          {content?.fieldSubject.map((item, index) => (
            <React.Fragment key={index}>
              {item.entity && (
                <span className="info subject">
                  <CustomHyphenation length={8}>
                    {item.entity.name}
                  </CustomHyphenation>
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
      }
      {content?.fieldOrganisation &&
        <span className="info organisation">
          <CustomHyphenation length={8}>
            {content.fieldOrganisation.entity.name}
          </CustomHyphenation>
        </span>
      }
    </div>
  );
};

export default PersonClassification;
