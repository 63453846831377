import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import {TeaserContent} from "../../content-base/paragraphs/teaser-overview-multi/multi-teaser";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const TeaserKvCategoryChildren = ({ content }) => {

  // content Mapping
  const teaserContent = {
    image: content.fieldImage?.entity.fieldMediaImage,
    title: content.name
  }

  return (
    <article className="node node-teaser teaser-kv-category-children">
      <TeaserContent
        content={teaserContent}
      />
    </article>
  );
}
export const teaserKvCategoryChildrenPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAt: PropTypes.shape({
    value: PropTypes.number,
    publishedAtOrNow: PropTypes.number,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserKvCategoryChildren.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserKvCategoryChildrenPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserKvCategoryChildren));
