import ErrorBoundary from "../../../../error-boundary";
import Image from "../../../image/image";
import {Textfit} from "react-textfit";
import Marquee from "react-fast-marquee";
import React from "react";
import {useSelector} from "react-redux";

export const TeaserSlide = ({item, index, fontFitted, playMarquee, onTextFitted, toggleMarquee}) => {
  const isMobile = useSelector((state) => state.appStore.isMobile);

  return(
    <div className="custom-slide" key={index}>
      <div className="inner-slide">
        <ErrorBoundary>
          {item.entity.fieldImageOrVideo?.entity.fieldMediaImage &&
            <Image
              className="background-media"
              data={item.entity.fieldImageOrVideo.entity.fieldMediaImage}
            />
          }
          {item.entity.fieldImageOrVideo?.entity.fieldMediaVideoFile &&
            <video
              className="background-media"
              src={item.entity.fieldImageOrVideo.entity.fieldMediaVideoFile.entity.url}
              playsInline={true}
              loop={true}
              muted={true}
              autoPlay={true}
              controls={false}
            />
          }
        </ErrorBoundary>
        <div className="slide-content">
          <article className="container">
            <div className="row">
              <div className="col-16 position-static">
                {item.entity.fieldTeaserueberschrift &&
                  <h3 className={fontFitted ? 'fitted' : 'unfitted'}>
                    {isMobile ? (
                      <>
                        {item.entity.fieldTeaserueberschrift}
                      </>
                    ) : (
                      <Textfit mode="single" max={400} onReady={onTextFitted}>
                        {item.entity.fieldTeaserueberschrift}
                      </Textfit>
                    )}
                  </h3>
                }
              </div>
            </div>
          </article>
        </div>
        {item.entity.fieldText &&
          <div
            className="text-wrapper"
            onMouseEnter={() => toggleMarquee(true)}
            onMouseLeave={() => toggleMarquee(false)}
          >
            <Marquee play={playMarquee} speed={50} gradient={false}>
              <div
                className="teaser text"
                dangerouslySetInnerHTML={{
                  __html: item.entity.fieldText.processed,
                }}
              />
            </Marquee>
          </div>
        }
      </div>
    </div>
  );
}