import React from "react";

export const PersonName = (props) => {
  return(
    <div className="name">
      {!props.content.fieldTitleAfterName && props.content?.fieldAcademicTitle &&
        <>
          {`${props.content?.fieldAcademicTitle} `}
        </>
      }
      {props.content?.fieldFirstName &&
        <>
          {`${props.content?.fieldFirstName} `}
        </>
      }
      {props.content?.fieldSurname &&
        <>
          {props.content?.fieldSurname}
        </>
      }
      {props.content.fieldTitleAfterName &&
        <>
          {`, ${props.content?.fieldAcademicTitle}`}
        </>
      }
      {!props.content.fieldTitleAfterName && !props.content?.fieldFirstName && !props.content?.fieldSurname && !props.content.fieldTitleAfterName &&
        <>{props.content.title}</>
      }
    </div>
  );
};

export default PersonName;
