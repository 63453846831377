import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { UpToTop24 } from "@carbon/icons-react";
import { animateScroll as scroll } from "react-scroll";
import Masonry from "react-masonry-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import LogoButton from "../../general-components/logo-button";
import menuQuery from "../../general-components/menu-query.graphql";

const mapStateToProps = reduxStore => ({
  currentLanguage: reduxStore.i18n.currentLanguage
});

class FooterSitemap extends Component {
  state = {};

  render() {
    return (
      <section className="footer-sitemap">
        <div className="row">
          <div className="col-16 col-md-3">
            <LogoButton />
          </div>

          <div className="col-md-12">
            <Masonry className="row">
              {!this.props.mainMenuQuery.loading &&
                this.props.mainMenuQuery.menuByName.links.map((item, index) => (
                  <nav className="col-16 col-md-3" key={index}>
                    <Link to={item.url.path}>{item.label}</Link>
                    {item.links.length > 0 && (
                      <ul>
                        {item.links.map((linkItem, linkIndex) => (
                          <li key={linkIndex}>
                            <Link to={linkItem.url.path}>{linkItem.label}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </nav>
                ))}
            </Masonry>
          </div>

          <div className="col-16 col-md-1">
            <div
              tabIndex={0}
              role="button"
              className="up-to-top"
              onClick={() => scroll.scrollToTop()}
            >
              <UpToTop24 />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FooterSitemap.propTypes = {
  mainMenuQuery: PropTypes.object.isRequired,
  menu: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(graphql(menuQuery, {
  options: (props) => ({
    variables: {
      name: props.menu,
      language: props.currentLanguage ? props.currentLanguage.toUpperCase() : 'DE',
    },
  }),
  name: "mainMenuQuery",
})(FooterSitemap));
