import React, { Component } from "react";
import SimpleNavigation from "./simple-navigation";

class SimpleHeader extends Component {
  render() {
    // Still existing for updates
    return (
      <SimpleNavigation
        location={this.props.location}
        menuRef={this.props.menuRef}
      />
    );
  }
}

export default SimpleHeader;
