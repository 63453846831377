import React from "react";

export const HighlightHmdk = ({ text, classNames }) => {

  // This component takes a passed text and replaces the occurances of "HMDK Stuttgart" with a highlighted Version.

  let highlightedText = text.replace(/HMDK Stuttgart/gi, "<span class='highlight-hmdk'>HMDK Stuttgart</span>");

  return (
    <div
      className={classNames || "text"}
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  );
};