import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserListWrap from "./components/component-teaserlist-wrap";
import TeaserEventList from "./events/component-teaser-event-list";
import ComponentTeaserlistCarousel from "./components/component-teaserlist-carousel";
import Link from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";
import { teaserNewsPropTypes } from "../../../teaser-base/news/teaser-news";
import { teaserEventPropTypes } from "../../../teaser-base/event/teaser-event";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";
import { teaserGeneralPropTypes } from "../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../teaser-base/project/teaser-projekt";
import {encodeUrl} from "../../../../lib/encode-url";
import { connect } from "react-redux";

/**
 * This is the component for the "Eierlegende Wollmilchsau" paragraph.
 * Try not not to use this! This is just for reference.
 *
 * Usage: Copy to
 * src/js/general-components/content-base/paragraphs/teaserliste/<content_type>/paragraphs-teaser-list<content_type>.jsx
 * and change/remove/add as needed.
 */

const mapStateToProps = (reduxStore) => ({
  microSite: reduxStore.appStore.microSite,
});

class ParagraphTeaserList extends Component {
  render() {
    const sectionClassNames = classNames({
        "paragraph paragraph-teaser-list": true,
        [`paragraph-teaser-list-${this.props.content.fieldAnzahlDerAnzuzeigendenI}`]: true,
        "paragraph paragraph-teaser-list-slick":
        this.props.content.fieldKarussel,
        [`paragraph-teaser-list-${this.props.content.fieldTyp}`]: true,
        [this.props.microSite ? this.props.microSite : "hmdk"]: true,
      }),
      headingClassNames = classNames({
        "heading-wrapper": true,
        "col-16": this.props.content.entityBundle !== "teaserlist_artists",
        "col-16 col-lg-8": this.props.content.entityBundle === "teaserlist_artists"
      }),
      carouselWrapperClassNames = classNames({
        "carousel-wrapper col-16": true,
        "col-lg-8": this.props.content.entityBundle === "teaserlist_artists"
      }),
      eventOnly = this.props.content.fieldTyp === "veranstaltung";

    let manualNodes = this.props.content.fieldBeitraegeAll;

    switch (this.props.content.fieldTyp) {
      case "news": {
        manualNodes = this.props.content.fieldBeitraegeNews;
        break;
      }
      case "artist": {
        manualNodes = this.props.content.fieldArtists;
        break;
      }
      case "person": {
        manualNodes = this.props.content.fieldBeitraegePerson;
        break;
      }
      case "lecture": {
        manualNodes = this.props.content.fieldLectures;
        break;
      }
      default: {
        manualNodes = this.props.content.fieldBeitraegeAll;
      }
    }

    manualNodes = manualNodes.filter((item) => !!item?.entity?.status);

    return (
      <section className={sectionClassNames}>
        <div className="container headline">
          <div className="row">
            {this.props.content.fieldHeading && (
              <div className={headingClassNames}>
                <h2 id={encodeUrl(this.props.content.fieldHeading)}>{this.props.content.fieldHeading}</h2>
                {this.props.content.entityBundle === "teaserlist_artists" &&
                  this.props.content.fieldMehrMeldungenButtonZeig &&
                  this.props.content.fieldMehrMeldungenButton &&
                  this.props.content.fieldMehrMeldungenButton?.title && (
                    <ErrorBoundary>
                      <Link
                        link={this.props.content.fieldMehrMeldungenButton}
                        className="btn more"
                      />
                    </ErrorBoundary>
                  )}
              </div>
            )}

          </div>
        </div>
        <div className="container list">
          <div className="row">
            {eventOnly && (
              <div className="col-16 col-lg-10 offset-lg-3">
                <div className="row">
                  <ErrorBoundary>
                    <TeaserEventList
                      title={this.props.content.fieldHeading}
                      count={
                        this.props.content.fieldAnzahlDerAnzuzeigendenI
                          ? this.props.content.fieldAnzahlDerAnzuzeigendenI
                          : 100
                      }
                      manualNodes={this.props.content.fieldBeitraegeVeranstaltung}
                      nodesConfig={this.props.content.fieldAutmatischOderManuell}
                      type={this.props.content.fieldTyp}
                      tags={this.props.content.fieldSchlagwort}
                      miniCalendarView={this.props.content.fieldMiniCalendarView}
                      overviewLink={this.props.content.fieldLinkZurUebersicht}
                    />
                  </ErrorBoundary>
                </div>
              </div>
            )}

            {!eventOnly && this.props.content.fieldKarussel && (
              <ErrorBoundary>
                <div className={carouselWrapperClassNames}>
                  <ComponentTeaserlistCarousel
                    manualNodes={manualNodes}
                    id={this.props.content.entityId}
                    nodesConfig={this.props.content.fieldAutmatischOderManuell}
                    count={
                      this.props.content.fieldAnzahlDerAnzuzeigendenI
                        ? this.props.content.fieldAnzahlDerAnzuzeigendenI
                        : 100
                    }
                    type={this.props.content.fieldTyp}
                    pagerFullPage={
                      this.props.content.fieldPagerAufVollseiten
                        ? this.props.content.entityId
                        : false
                    }
                    pagerFullPageOverviewLink={
                      this.props.content.fieldLinkZurUebersicht
                    }
                    tags={this.props.content.fieldSchlagwort}
                    subject={this.props.content.fieldSubject}
                    faculty={this.props.content.fieldFaculty}
                    personStatus={this.props.content.fieldStatus}
                    institute={this.props.content.fieldInstitute}
                    organisation={this.props.content.fieldOrganisation}
                    function={this.props.content.fieldFunction}
                  />
                </div>
              </ErrorBoundary>
            )}

            {!eventOnly && !this.props.content.fieldKarussel && (
              <ErrorBoundary>
                <TeaserListWrap
                  manualNodes={manualNodes}
                  id={this.props.content.entityId}
                  nodesConfig={this.props.content.fieldAutmatischOderManuell}
                  count={this.props.content.fieldAnzahlDerAnzuzeigendenI || 100}
                  type={this.props.content.fieldTyp}
                  pagerFullPage={
                    this.props.content.fieldPagerAufVollseiten
                      ? this.props.content.entityId
                      : false
                  }
                  pagerFullPageOverviewLink={
                    this.props.content.fieldLinkZurUebersicht
                  }
                  tags={this.props.content.fieldSchlagwort}
                />
              </ErrorBoundary>
            )}
          </div>
        </div>

        {this.props.content.entityBundle !== "teaserlist_artists" &&
          this.props.content.fieldMehrMeldungenButtonZeig &&
          this.props.content.fieldMehrMeldungenButton?.title && (
            <div className="container more">
              <div className="row">
                <div className="col-16 offset-md-11 col-md-5 justify-content-md-end">
                  <ErrorBoundary>
                    <Link
                      link={this.props.content.fieldMehrMeldungenButton}
                      className="btn solid square more"
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphTeaserList.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityId: PropTypes.string,
    entityBundle: PropTypes.string,
    fieldKarussel: PropTypes.bool,
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldMehrMeldungenButtonZeig: PropTypes.bool,
    fieldTyp: PropTypes.oneOf(["news", "person", "veranstaltung", "artist", "lecture", "all"]),
    fieldHeading: PropTypes.string,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
    fieldLectures: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          entityId: PropTypes.string,
          entityBundle: PropTypes.string,
          created: PropTypes.number,
          entityLabel: PropTypes.string,
          entityUrl: PropTypes.shape({
            __typename: PropTypes.string,
            path :PropTypes.string,
          }),
          fieldCreditPoints: PropTypes.string,
          fieldDays: PropTypes.array,
          fieldExternalLecturer: PropTypes.array,
          fieldExtraText: PropTypes.shape({
            __typename: PropTypes.string,
            processed: PropTypes.string,
          }),
          fieldInternalLecturer: PropTypes.arrayOf(
            PropTypes.shape({
              __typename: PropTypes.string,
              entity: PropTypes.shape({
                __typename: PropTypes.string,
                entityLabel: PropTypes.string,
                entityUrl: PropTypes.shape({
                  __typename: PropTypes.string,
                  path: PropTypes.string,
                }),
              }),
            }),
          ),
          fieldLectureTag: PropTypes.array,
          fieldLectureType: PropTypes.array,
          fieldOpenFor: PropTypes.string,
          fieldRegistrationAt: PropTypes.string,
          fieldRegistrationBy: PropTypes.string,
          fieldRegistrationElearning: PropTypes.bool,
          fieldRoomOrOnline: PropTypes.string,
          fieldStartingFromText: PropTypes.string,
          fieldSubtitle: PropTypes.string,
          fieldText: PropTypes.shape({
            __typename: PropTypes.string,
            processed: PropTypes.string,
          }),
          fieldTimes: PropTypes.array,
          path: PropTypes.shape({
            __typename: PropTypes.string,
            alias: PropTypes.string,
          }),
        }),
      }),
    ),
    fieldColorScheme: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        fieldColorVariable: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    fieldAutmatischOderManuell: PropTypes.oneOf([
      "Automatisch (chronologisch)",
      "Manuell",
    ]),
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldMehrMeldungenButton: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldBeitraegeAll: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.oneOfType([
          teaserNewsPropTypes,
          teaserEventPropTypes,
          teaserPersonPropTypes,
          teaserGeneralPropTypes,
          teaserProjectPropTypes,
        ]),
      })
    ),
    fieldBeitraegeVeranstaltung: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserEventPropTypes,
      })
    ),
    fieldBeitraegeNews: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserNewsPropTypes,
      })
    ),
    fieldBeitraegePerson: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserPersonPropTypes,
      })
    ),
  }),
};

export default connect(mapStateToProps)(ParagraphTeaserList);
