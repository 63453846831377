import React, { Component } from "react";
import {self} from "../../config";
import PropTypes from "prop-types";

const Play = (props) => {
  return (
    <div className={"play-wrapper"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        fill="none"
        viewBox="0 0 128 128"
      >
        <path
          fill="#FF00BC"
          fillRule="evenodd"
          d="M64 128c35.346 0 64-28.654 64-64 0-35.346-28.654-64-64-64C28.654 0 0 28.654 0 64c0 35.346 28.654 64 64 64zM48.5 91.09L96 63.668 48.5 36.242v54.849z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
}

export default Play;
