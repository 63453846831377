import React from "react";
import {FormattedMessage} from "react-intl";
import LazyLoad from "react-lazyload";
import {restHostBackend} from "../config";
import InternalInfo from "./internal-info";
import {Link} from "react-router-dom";

const Content = (props) => {
  return(
    <>
      <div className="margin-wrapper">
        {props.result.title &&
          <div className="tags">
            <div className="tag type">
              {props.result.type === 'person' &&
                <FormattedMessage id="type.person" />
              }

              {props.result.type === 'veranstaltung' &&
                <FormattedMessage id="type.event" />
              }

              {props.result.type.includes('landingpage') &&
                <FormattedMessage id="type.page" />
              }

              {props.result.type === 'news' &&
                <FormattedMessage id="type.news" />
              }

              {props.result.type === 'course' &&
                <FormattedMessage id="type.course" />
              }

              {props.result.type === 'artist' &&
                <FormattedMessage id="type.artist" />
              }

              {props.result.type === 'ensemble' &&
                <FormattedMessage id="type.ensemble" />
              }
            </div>
          </div>
        }
        <div className="title">
          <span dangerouslySetInnerHTML={{ __html: props.result.title }}/>
        </div>
      </div>
      <LazyLoad offset={200}>
        {props.result.field_internal ? (
          <InternalInfo />
        ) : (
          <>
            {props.result.image_1 &&
              <img className="hover-image" src={`${restHostBackend}${props.result.image_1}`} alt={`Ein Bild zum Suchergebnis: ${props.result.title}`}/>
            }
            {props.result.image_2 &&
              <img className="hover-image" src={`${restHostBackend}${props.result.image_2}`} alt={`Ein Bild zum Suchergebnis: ${props.result.title}`}/>
            }
            {props.result.image_3 &&
              <img className="hover-image" src={`${restHostBackend}${props.result.image_3}`} alt={`Ein Bild zum Suchergebnis: ${props.result.title}`}/>
            }
          </>
        )}
      </LazyLoad>
    </>
  );
}

export const SearchResult = (props) => {
  const url = props.result.url.replace(restHostBackend, "");

  return (
    <>
      {props.result.field_internal ? (
        <div
          className="internal-no-link"
        >
          <Content
            result={props.result}
          />
        </div>
      ) : (
        <Link
          to={url}
          data-title={props.result.title}
          className="search-link"
        >
          <Content
            result={props.result}
          />
        </Link>
      )}
    </>
  );
};

export default SearchResult;
