import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomHyphenation  from "./../../custom-hyphenation"

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserGeneral extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    if (this.props.item) {
      return (
        <article className="node node-teaser teaser-news">
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          <Link
            onClick={() =>
              this.props.dispatch(
                pagerFullPageContextAction(this.props.pagerFullPage)
              )
            }
            className="flex-wrap"
            to={
              this.props.item.entityUrl
                ? this.props.item.entityUrl.path
                : this.props.item.path.alias
            }
          >
            {(!!this.props.item.fieldTeaserbild || !!this.props.item.fieldBild) ? (
              <div className="image-wrapper">
                {this.props.item.fieldTeaserbild &&
                  <Image
                    data={this.props.item.fieldTeaserbild.entity.fieldMediaImage}
                    nodeTitle={this.props.item.title}
                  />
                }
                {this.props.item.fieldBild &&
                  <Image
                    data={this.props.item.fieldBild.entity.fieldMediaImage}
                    nodeTitle={this.props.item.title}
                  />
                }
              </div>
            ) : (
              <div className="color-wrapper">
                <div className="placeholder"/>
              </div>
            )}
            <div className="text-wrapper">
              <h3>
                <CustomHyphenation length={12}>
                  {this.props.alternativeLabel || this.props.item.title}
                </CustomHyphenation>
              </h3>
            </div>
          </Link>
        </article>
      );
    }
  }
}

export const teaserGeneralPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTeaserbild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserGeneral.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserGeneralPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserGeneral));
