import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { List32, Grid32 } from "@carbon/icons-react";
import { FormattedMessage } from "react-intl";

import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../loading-indicator";
import TeaserBase from "../../../teaser-base/teaser-base";
import ComponentFilterCloud from "../extended-teaser-list/components/component-filter-cloud";
import {
  timelineSetActiveFilterAction,
  timelineRemoveActiveFilterAction,
} from "./actions-timeline";
import { teaserNewsPropTypes } from "../../../teaser-base/news/teaser-news";
import { Link } from "react-router-dom";

import VisibilitySensor from "react-visibility-sensor";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{timelineConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  timelineConfig: reduxStore.timeLine.config,
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite,
});

/*
 * @todo: Pager.
 */
class ParagraphTimeline extends Component {
  state = {
    activeFilter: {},
    displaySwitch: "grid",
    showAnimation: true,
  };

  stopAnimation = (isVisible) => {
    // Only trigger Visibility animation one time. Module lacks "once" functionality so -> DIY.
    if (this.state.showAnimation && isVisible) {
      this.setState({
        showAnimation: false,
      });
    }
  };

  // Preparing "ids to get" to ensure the news teasers are correctly added to the timeline columns
  getIds = (index, type) => {
    if (type === "left") {
      if (this.props.content.fieldNewsCta) {
        // get all even IDs, except for first element
        return ((index + 1) % 2 !== 0) && index !== 0;
      } else {
        // get all even IDs, including first element
        return ((index + 1) % 2 !== 0);
      }
    } else {
      if (this.props.content.fieldNewsCta) {
        // get all even IDs, including first element
        return index === 0 || (index + 1) % 2 === 0;
      } else {
        // get all uneven IDs, not including first element
        return (index + 1) % 2 === 0;
      }
    }
  }

  changeActiveFilter = (activeFilter) => {
    if (
      this.state.activeFilter &&
      this.state.activeFilter.entityId === activeFilter.entityId
    ) {
      this.setState({ activeFilter: {} }, () => {
        this.props.dispatch(
          timelineRemoveActiveFilterAction(this.props.content.entityId)
        );
      });
    } else {
      this.setState({ activeFilter }, () => {
        const config = {
          timeLineId: this.props.content.entityId,
          config: activeFilter,
        };

        this.props.dispatch(timelineSetActiveFilterAction(config));
      });
    }
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-timeline": true,
    });

    let items =
        this.props.nodes.nodeQuery &&
        this.props.nodes.nodeQuery.entities.length > 0
          ? this.props.nodes.nodeQuery.entities
          : [];

    let stickyItems = items.filter((item) => item.sticky);

    items = items.filter((item) => !item.sticky);

    items = [...stickyItems, ...items];

    if (items.length > 0) {
      return (
        <section className={sectionClassNames}>
          <div className="container">
            {/* If User sets a Link to the overview, the timeline is rendered as a shorter (5 News), teaser version. If not, the filters will show and a max of 50 Elements are queried. */}
            {(this.props.content.fieldFilterwolke ||
              this.props.content.fieldListenumschalter) && (
              <div className="row timeline-filter">
                <div className="filter col-16">
                  {/* Note: Currently only works with filter={["fieldSchlagwort"]}, no custom filters!
                    In the timeline module, every filter change triggers a new (filtered) query, so the filters
                    would be needed to be variable in the graphql query (which does not work).
                  */}
                  {this.props.content.fieldFilterwolke && (
                    <ComponentFilterCloud
                      items={this.props.nodes.nodeQuery.entities}
                      filter={["fieldSchlagwort"]}
                      activeFilter={[this.state.activeFilter]}
                      changeActiveFilter={this.changeActiveFilter}
                      wrap={false}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="row teaser-wrapper">
              <div className="col-16 col-sm-8 col-lg-6">
                {this.props.content.fieldNewsCta && (
                  <VisibilitySensor
                    partialVisibility={false}
                    active={this.state.showAnimation}
                    onChange={(isVisible) => this.stopAnimation(isVisible)}
                    minTopValue={300}
                  >
                    {({ isVisible }) => (
                      <div
                        className={`animation-wrapper ${
                          (isVisible || !this.state.showAnimation) ? "visible" : "not-visible"
                        }`}
                      >
                        <Link
                          className={`cta ${
                            this.props.content.fieldNewsCta.title.length > 15
                              ? "smaller"
                              : "standard"
                          }`}
                          to={this.props.content.fieldNewsCta.url.path}
                        >
                          {this.props.content.fieldNewsCta.title}
                        </Link>
                      </div>
                    )}
                  </VisibilitySensor>
                )}
                {items
                  .filter((item, index) => this.getIds(index, "left"))
                  .map((item, index) => (
                    <TeaserBase item={item} key={index} viewMode={"timeline"} />
                  ))}
              </div>

              <div className="col-16 col-sm-8 col-lg-6 timeline-column-2">
                {items
                  .filter((item, index) => this.getIds(index, "right"))
                  .map((item, index) => (
                    <TeaserBase item={item} key={index} viewMode={"timeline"} />
                  ))}
              </div>
            </div>

            {/*loadMore && !this.props.content.fieldLinkZurUebersicht && (
              <div className="row load-more">
                <div className="col-16 d-flex justify-content-center">
                  <div
                    className="btn btn-primary square solid-grey"
                    onClick={() => this.props.nodes.loadMore()}
                  >
                    <span>
                      <FormattedMessage id="load_more" />
                    </span>
                  </div>
                </div>
              </div>
            )*/}

            {this.props.content.fieldLinkZurUebersicht && (
              <div className="row load-more">
                <div className="col-16 d-flex justify-content-center">
                  <Link
                    to={this.props.content.fieldLinkZurUebersicht?.url?.path}
                    className="btn btn-primary square solid-grey"
                  >
                    {this.props.content.fieldLinkZurUebersicht?.title}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </section>
      );
    }

    return <LoadingIndicator />;
  }
}

ParagraphTimeline.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string.isRequired,
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldFilterwolke: PropTypes.bool,
    fieldListenumschalter: PropTypes.bool,
    fieldZeigeMehrLaden: PropTypes.bool,
    fieldTypenTimeline: PropTypes.arrayOf(
      PropTypes.oneOf(["news", "blogbeitrag"])
    ),

    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        targetId: PropTypes.number,
      })
    ),
    fieldNewsCta: PropTypes.shape({
      __typename: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.shape({
        __typename: PropTypes.string,
        path: PropTypes.string,
      }),
    }),
    fieldLinkZurUebersicht: PropTypes.shape({
      __typename: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.shape({
        __typename: PropTypes.string,
        path: PropTypes.string,
      }),
    }),
  }),
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.shape({
    nodeQuery: PropTypes.shape({
      count: PropTypes.number,
      entities: PropTypes.arrayOf(teaserNewsPropTypes),
    }),
    loadMore: PropTypes.func,
  }),

};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    options: (props) => {
      const tagConfig = props.timelineConfig.filter(
          (item) => item.timeLineId === props.content.entityId
        ),
        tagFiltered = tagConfig.length === 1 && tagConfig[0].config.entityId;

      let tags = [];

      // TODO: Not tag multi select, just one tag at a time.
      if (tagFiltered) {
        tags = [tagConfig[0].config.entityId];
      }

      if (props.content.fieldSchlagwort.length > 0) {
        for (const tag of props.content.fieldSchlagwort) {
          tags = [...tags, String(tag.targetId)];
        }
      }

      return {
        variables: {
          limit: props.content.fieldLinkZurUebersicht ? 5 : 50,
          type: props.content.fieldTypenTimeline,
          tag: tags,
          microSiteFilterEnabled: true,
          microSiteFilter: props.microSite ? props.microSite : "hmdk",
          filterTagEnabled: tags.length > 0,
          language: props.currentLanguage.toUpperCase(),
        },
      };
    },
    props: (props) => ({
      nodes: {
        ...props.nodes
      },
    }),
  })(ParagraphTimeline)
);
