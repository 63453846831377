import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { isMobileAction, mainSettingsAction } from "./app-actions";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import Landingpage from "./landingpage/landingpage";
import Course from "./course/course";
import Event from "./event/event";
import Person from "./person/person";
import News from "./news/news";
import footerConfigQuery from "footer-config-query.graphql";
import PageFooter from "./footer/page-footer";
import menuQuery from "./general-components/menu-query.graphql";
import SimpleHeader from "./header/simple-header";
import ErrorBoundary from "./error-boundary";
import Error404 from "./error-404";
import NodeRevision from "./backend/NodeRevision";
import ResetPasswordWithTempPasswordForm from "./intern/reset-password-with-temp-password-form";
import ForgotPasswordForm from "./intern/forgot-password-form";
import LoginForm from "./intern/login-form";
import { authenticationLoginAction } from "./intern/redux-authentication";
import { tokenExpired } from "./intern/util";
import configQuery from "./config-query.graphql";

import getConfigID from "./lib/get-config-id";
import {enableBodyScroll} from "body-scroll-lock-upgrade";
import {scrollOptions} from "./lib/scroll-options";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({ reduxStore });

const messages = {
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 */
class App extends Component {
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  menu = React.createRef();

  updateDimensions = () => {
    if (window.innerWidth >= 768) {
      this.props.dispatch(isMobileAction(false));
    } else {
      this.props.dispatch(isMobileAction(true));
    }
  };

  componentDidMount() {
    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;

    // Clear login status if jwt is expired.
    if (
      this.props.reduxStore.auth.userLoginData.access_token &&
      this.props.reduxStore.auth.userLoginData.jwt.exp <
        Math.round(new Date().getTime() / 1000)
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    // Clear login status if jwt is expired.
    if (
      this.props.reduxStore.auth.userLoginData.access_token &&
      tokenExpired(
        this.props.reduxStore.auth.userLoginData.access_token,
        this.props.reduxStore.auth.userLoginData.jwt.exp
      )
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    if (this.props.mainSettingsConfig.configPagesQuery?.entities[0] && !this.props.reduxStore.appStore.mainSettings) {
      this.props.dispatch(mainSettingsAction(this.props.mainSettingsConfig.configPagesQuery?.entities[0]));
    }

    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0);
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
      enableBodyScroll(document.getElementById('site-header'), scrollOptions);
    }
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.reduxStore.i18n.currentLanguage}
        defaultLocale="de"
        messages={messages[this.props.reduxStore.i18n.currentLanguage]}
      >
        <div className="main-content">
          <header
            id="site-header"
            data-is-frontpage={this.props.location.pathname === "/"}
            ref={this.menu}
          >
            <ErrorBoundary>
              <SimpleHeader
                location={this.props.location}
                menuRef={this.menu}
              />
            </ErrorBoundary>
          </header>

          <main className="main-page-content">
            <ErrorBoundary>
              <Switch
                location={this.props.location}
                key={this.props.location.pathname}
              >
                {/* Internal Pages */}
                <Route exact path="/intern/login" component={LoginForm} />
                <Route
                  exact
                  path="/intern/forgot-password"
                  component={ForgotPasswordForm}
                />
                <Route
                  exact
                  path="/intern/reset-pass/temp"
                  component={ResetPasswordWithTempPasswordForm}
                />
                {/* Public Pages */}
                <Route
                  exact
                  path="/:language?/projekt/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/studiengang/:alias"
                  component={Course}
                />
                <Route
                  exact
                  path="/:language?/course/:alias"
                  component={Course}
                />
                <Route exact path="/:language?/news/:alias" component={News} />
                <Route
                  exact
                  path="/:language?/veranstaltung/:alias"
                  component={Event}
                />
                <Route
                  exact
                  path="/:language?/kuenstler-in/:alias"
                  component={Person}
                />
                <Route
                  exact
                  path="/:language?/ensemble/:alias"
                  component={Person}
                />
                <Route
                  exact
                  path="/:language?/artist/:alias"
                  component={Person}
                />
                <Route
                  exact
                  path="/:language?/person/:alias"
                  component={Person}
                />
                <Route exact path="/:language?/news/:alias" component={News} />
                <Route
                  exact
                  path="/node/:nodeId/revisions/:revisionId/view"
                  component={NodeRevision}
                />
                <Route exact path="/" component={Landingpage} />
                <Route
                  exact
                  path="/:language?/:alias"
                  component={Landingpage}
                />
                <Route path="*" component={Error404} status={404} />
              </Switch>
            </ErrorBoundary>
          </main>

          <footer id="pageFooter">
            <ErrorBoundary>
              <PageFooter
                footerConfig={this.props.footerConfig.configPagesById}
                menu={this.props.menuQuery.menuByName}
                legalMenu={this.props.legalMenuQuery.menuByName}
              />
            </ErrorBoundary>
          </footer>
        </div>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  footerConfig: PropTypes.object.isRequired,
  menuQuery: PropTypes.object.isRequired,
  reduxStore: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  compose(
    graphql(footerConfigQuery, {
      options: (props) => ({
        variables: {
          id: getConfigID(props.reduxStore?.appStore?.microSite)
        },
      }),
      name: "footerConfig",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "footer",
          language: props.reduxStore.i18n.currentLanguage
            ? props.reduxStore.i18n.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "menuQuery",
    }),
    graphql(configQuery, {
      options: {
        variables: { name: "main_settings" },
      },
      name: "mainSettingsConfig",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "legal-menu",
          language: props.reduxStore.i18n.currentLanguage
            ? props.reduxStore.i18n.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "legalMenuQuery",
    })
  )(withRouter(App))
);
