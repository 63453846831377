import React, { Component } from 'react';
import { self } from "../config";
import { searchQuery, buildConditions } from './search-query.js';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { searchOpenAction, menuOpenAction } from '../app-actions';
import {FormattedMessage} from "react-intl";
import SearchResult from "./search-result";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {scrollOptions} from "../lib/scroll-options";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = reduxStore => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  searchString: reduxStore.appStore.searchString,
  searchResults: reduxStore.appStore.searchResults,
  searchOpen: reduxStore.appStore.searchOpen,
  overlayOpen: reduxStore.appStore.overlayOpen,
  menuOpen: reduxStore.appStore.menuOpen,
  microSite: reduxStore.appStore.microSite
});

class Search extends Component {

  input = React.createRef();

  state = {
    // Wait until 750 ms have passed, then execute the inputChange handler.
    debounceTime: 750,
    emptyResults: false,
    // If the search query request is currently loading.
    isSearching: false,
    searchResults: [],
    filteredResults: [],
    searchResultsComplete: [],
    searchResultsCompleteOpen: false,
    searchString: '',
    showResultCount: false,
    availableFilters: [],
    filters: [
      {
        name: <FormattedMessage id="type.course" />,
        value: 'course'
      },
      {
        name: <FormattedMessage id="type.ensemble" />,
        value: 'ensemble'
      },
      {
        name: <FormattedMessage id="type.artist" />,
        value: 'artist'
      },
      {
        name: <FormattedMessage id="type.person" />,
        value: 'person'
      },
      {
        name: <FormattedMessage id="type.event" />,
        value: 'veranstaltung'
      },
      {
        name: <FormattedMessage id="type.news" />,
        value: 'news'
      },
      {
        name: <FormattedMessage id="type.page" />,
        value: 'landingpage'
      }
    ]
  };

  onMouseEnter = e => {
    if (e.target.offsetWidth > (window.innerWidth - 100)) {
      e.target.style.marginLeft = `-${e.target.offsetWidth - (window.innerWidth - 175 )}px`;
    }
  };

  onMouseLeave = e => {
    if (e.target.offsetWidth > (window.innerWidth - 100)) {
      e.target.style.marginLeft = 0;
    }
  };

  handleMouseMove = e => {

  }

  filterResults = (filter, e) => {
    let tags = document.querySelectorAll('.filter-wrapper .tag');

    [...tags].map(tag => {
      tag.classList.remove('active');
    });

    if (filter === 'reset') {
      const filteredResults = this.state.searchResults;

      this.setState({
        filteredResults
      });

      tags[0].classList.add('active');
    } else if (filter.subValue) {
      // Filter Newskategorie
      e.target.classList.add('active');
      const filteredResults = this.state.searchResults.filter(result => result.field_newskategorie === filter.subValue);

      this.setState({
        filteredResults
      });
    } else {
      // Filter Contenttype
      e.target.classList.add('active');
      const filteredResults = this.state.searchResults.filter(result => result.type === filter.value);

      this.setState({
        filteredResults
      });
    }
  }

  reduceFilters = (results) => {
    let possibleFilters = [],
      availableFilters = [];

    results.map(result => {
      if (result.field_newskategorie) {
        possibleFilters.push(result.field_newskategorie);
      } else {
        possibleFilters.push(result.type);
      }
    });
    this.state.filters.map(filter => {
      if (filter.subValue && possibleFilters.indexOf(filter.subValue) > -1) {
        availableFilters.push(filter);
      }
      if (!filter.subValue && possibleFilters.indexOf(filter.value) > -1) {
        availableFilters.push(filter);
      }
    });

    return availableFilters;
  }

  handleInputChange = _debounce(value => {
    this.setState({ emptyResults: false });

    if (value.length > 0) {
      this.setState({
        isSearching: true,
        showResultCount: true
      });

      const isKV = this.props.microSite === "kv",
        isHMDK = !this.props.microSite,
        siteChannel = this.props.microSite || "hmdk",
        contentType = !this.props.microSite ? 'landingpage' : `landingpage_${this.props.microSite}`;

      console.log(buildConditions(siteChannel, contentType, isKV, isHMDK));

      // Fetch Search query
      this.props.client.query({
        query: searchQuery,
        variables: {
          searchString: value,
          siteChannel: siteChannel,
          isKV: isKV,
          isHMDK: isHMDK,
          contentType: contentType,
          conditions: buildConditions(siteChannel, contentType, isKV, isHMDK)
        }
      }).then(response => {
        this.setState({
          isSearching: false,
          searchResults: response.data.searchAPISearch.documents,
          filteredResults: response.data.searchAPISearch.documents,
          availableFilters: this.reduceFilters(response.data.searchAPISearch.documents)
        });

        if (response.data.searchAPISearch.result_count === 0) {
          this.setState({ emptyResults: true });
        }
      });
    }
  }, this.state.debounceTime);

  openSearch = (open) => {
    this.props.dispatch(searchOpenAction(open));
    setTimeout(() => {
      document.getElementById('search-input').focus();
    }, 500);
    if (open) {
      disableBodyScroll(document.getElementById('site-header'), scrollOptions);
    } else {
      enableBodyScroll(document.getElementById('site-header'), scrollOptions);
    }
  }

  clickLink = (e) => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('fullscreen-menu-open');
      this.props.dispatch(searchOpenAction(false));
      this.props.dispatch(menuOpenAction(false));
      this.setState({
        searchString: '',
        searchResults: [],
        filterResults: []
      });
    }
  }

  render() {

    return (
      <div className="search">
        <div className="toggle-search" onClick={() => this.openSearch(true)}>
          <FormattedMessage id="search.toggle" />
          <img className="search" alt={"Suche - Lupe"} src={`${self}/search.svg`}/>
        </div>
        <div className="search-container" data-overlay-open={this.props.searchOpen}>
          <div className={"inner-wrapper"}>
            <div className="container">
              <div className="row">
                <div className="col-16 search-title-wrapper">
                  <p className="search-title">
                    <FormattedMessage id="search.title" />
                  </p>
                  <button
                    className="close-wrapper"
                    onClick={ () => this.openSearch(false)}
                    tabIndex={0}
                  >
                    <img className="close" src={`${self}/close-icon.svg`} alt={"Icon Suche schließen / zurücksetzen"}/>
                  </button>
                </div>
                <div className="col-16 col-lg-11">
                  <div className={`${this.state.searchString.length > 0 ? 'searching' : ''} search-input`}>
                    <input
                      ref={this.input}
                      id="search-input"
                      tabIndex="1"
                      autoComplete="off"
                      placeholder="Suche"
                      type="search"
                      name="searchString"
                      onChange={({ target: { value } }) => {
                        this.setState({ searchString: value });
                        this.handleInputChange(value);
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                    <div className="actions-wrapper">
                      <img className="search" src={`${self}/search.svg`} alt={"Icon Suche"}/>
                    </div>
                  </div>
                </div>
                {this.state.searchString.length > 0 && this.state.availableFilters &&
                  <>
                    {this.state.filteredResults && this.state.filteredResults.length > 0 ? (
                      <div className="col-16 offset-lg-1 col-lg-14 filter-wrapper">
                        <div className="tag filter active" onClick={(e) => this.filterResults('reset', e)}>
                          <FormattedMessage id="all" />
                        </div>
                        {this.state.availableFilters.map((filter, index) =>
                          <>
                            {this.state.filteredResults.indexOf(filter.value) &&
                              <div className="tag filter" key={index} onClick={(e) => this.filterResults(filter, e)}>
                                {filter.name}
                              </div>
                            }
                          </>
                        )}
                      </div>
                    ) : null}

                    {this.state.emptyResults ? (
                      <div className="col-16 offset-lg-1 col-lg-10">
                        <div className="result-count no-results">
                          <FormattedMessage id="search.no_results" />
                        </div>
                      </div>
                    ) : null}
                  </>
                }
                <div className="col-16 offset-lg-1 col-lg-14">
                  {this.state.filteredResults && this.state.searchString.length > 0 && this.state.filteredResults.length > 0 &&
                    <div className="result-count">
                      {this.state.showResultCount ? (
                        <span className="count">
                            {this.state.filteredResults.length}
                          </span>
                      ) : (
                        <span className="count">
                            ...
                          </span>
                      )}
                      <FormattedMessage id="results" />
                    </div>
                  }
                </div>
                <div className={"col-16 offset-lg-1 col-lg-14"}>
                  {this.state.filteredResults.length > 0 && this.state.searchString.length > 0 &&
                    <ul className="results-wrapper">
                      {this.state.filteredResults.map((result, index) =>
                        <React.Fragment key={index}>
                          <li
                            className="search-result"
                            key={index}
                            onMouseMove={e => this.handleMouseMove(e)}
                            onClick={e => this.clickLink(e) }
                            onMouseEnter={e => this.onMouseEnter(e)}
                            onMouseLeave={e => this.onMouseLeave(e)}
                          >
                            <SearchResult
                              result={result}
                            />
                          </li>
                        </React.Fragment>
                      )}
                    </ul>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  client: PropTypes.object.isRequired
};

export default (connect(mapStateToProps)(withApollo(withRouter(Search))));