import React  from 'react';


const GalleryIndexInfo = (props) => {

  const nextSlide = () => {
    props.sliderRef.current.slickNext();
  }

  return(
    <div className={"index-info"} onClick={() => {nextSlide()}}>
      {props.slideIndex + 1}/{props.content.length}
    </div>
  );
}

export default GalleryIndexInfo;