import React, { Component } from "react";
import { Link } from "react-router-dom";
import { self } from "../config";
import {useSelector} from "react-redux";

const LogoButton = (props) => {
  const microSite = useSelector(reduxStore => reduxStore.appStore.microSite);

  let logo = '';

  const hmdkLogo = props.type === "extended" ? 'hmdk-logo.svg' : 'hmdk-wortmarke.svg',
    wtLogo = props.type === "extended" ? 'logo-wt-lang.svg' : 'logo-wt-kurz.svg',
    gdfLogo = props.type === "extended" ? 'logo-gdf-lang.svg' : 'logo-gdf-kurz.svg',
    kvLogo = props.type === "extended" ? 'logo-mh-lang.svg' : 'logo-mh-kurz.svg';

  switch (microSite) {
    case false: {
      logo = hmdkLogo;
    }
    break;
    case "wt": {
      logo = wtLogo;
    }
    break;
    case "gdf": {
      logo = gdfLogo;
    }
    break;
    case "kv": {
      logo = kvLogo;
    }
    break;
  }

  return (
    <Link className="site-logo-button" to="/">
      <img
        src={`${self}/logos/${logo}`}
        alt={"HMDK Logo"}
      />
      <span className="sr-only">
        HMDK
      </span>
    </Link>
  );
}


LogoButton.propTypes = {};

export default LogoButton;
