import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { LinkPropType } from "../../../link/link";
import Marquee from "react-fast-marquee";
import { LogoPartner } from "./logo-partner";
import {connect} from "react-redux";
import {encodeUrl} from "../../../../lib/encode-url";

const mapStateToProps = (reduxStore) => ({
  isMobile: reduxStore.appStore.isMobile
});

class ParagraphLogosPartners extends Component {
  state = {};

  render() {
    // Determine how much Logos are fitting onto screen before marquee starts
    let maxViewportFittingLength = 1;

    if (!this.props.isMobile) {
      maxViewportFittingLength = 4;
    }

    let marqueeNeeded = this.props.content.fieldLogosPartners.length > maxViewportFittingLength;

    const sectionClassNames = classNames({
      "paragraph paragraph-logos-partners": true,
      "marquee": marqueeNeeded,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldHeading &&
          <div className="container">
            <div className="row">
              <div className={`col-16`}>
                <div className="heading" id={encodeUrl(this.props.content.fieldHeading)}>{this.props.content.fieldHeading}</div>
              </div>
            </div>
          </div>
        }
        {this.props.content.fieldLogosPartners &&
          <div className="container logos">
            <div className="row">
              <div className={`col-16`}>
                <div
                  className="row"
                >
                  {this.props.content.fieldLogosPartners.length > maxViewportFittingLength ? (
                    <Marquee speed={50} gradient={false}>
                      {this.props.content.fieldLogosPartners.map((item, index) => (
                        <LogoPartner
                          content={item}
                          key={index}
                          marquee={true}
                        />
                      ))}
                    </Marquee>
                  ) : (
                    <>
                      {this.props.content.fieldLogosPartners.map((item, index) => (
                        <LogoPartner
                          content={item}
                          key={index}
                        />
                      ))}
                    </>
                  )
                }
                </div>
              </div>
            </div>
          </div>
        }
      </section>
    );
  }
}

ParagraphLogosPartners.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldHeading: PropTypes.string.isRequired,
    fieldLogosPartners: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          fieldLink: LinkPropType,
          fieldMediaImage1: PropTypes.shape({
            style: PropTypes.shape({
              __typename: PropTypes.string,
              url: PropTypes.string,
            }).isRequired,
            styleFullPage: null,
            desktop: null,
            mobile: null,
            alt: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(ParagraphLogosPartners)
