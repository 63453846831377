import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";

import { pagerFullPageContextAction } from "../../../app-actions";
import PersonImage from "../../../person/person-image";
import PersonName from "../../../person/person-name";
import {FormattedMessage} from "react-intl";
import PersonClassification from "../../../person/person-classification";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserPerson extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {

    return (
      <article className="node node-teaser teaser-person colored-background" data-color={this.props.index % 4}>
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        {this.props.item.fieldEMailMe && this.props.item.fieldMail &&
          <a
            href={`mailto:${this.props.item.fieldMail}`}
            className="mail-me"
            target={"_blank"}
          >
            <FormattedMessage id="contact.mail_me" />
          </a>
        }
        <Link
          onClick={() =>
            this.props.dispatch(
              pagerFullPageContextAction(this.props.pagerFullPage)
            )
          }
          className="flex-wrap"
          to={
            this.props.item.entityUrl
              ? this.props.item.entityUrl.path
              : this.props.item.path.alias
          }
        >
          <div className="colored-wrapper">
            <PersonImage
              content={this.props.item}
              styleSquare={true}
            />
          </div>
          <div className="info-wrapper">
            <PersonClassification content={this.props.item}/>
            <PersonName content={this.props.item}/>
          </div>
        </Link>
      </article>
    );
  }
}

export const teaserPersonPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  __typename: PropTypes.string,
  title: PropTypes.string,
  fieldEMailMe: PropTypes.bool,
  fieldFaculty: PropTypes.array,
  fieldFirstName: PropTypes.string,
  fieldFunction: PropTypes.array,
  fieldInstitute: PropTypes.any,
  fieldAcademicTitle: PropTypes.string,
  fieldMail: PropTypes.string,
  fieldTelefon: PropTypes.string,
  fieldMobil: PropTypes.string,
  created: PropTypes.number,
  entityBundle: PropTypes.string,
  fieldModules: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        entityId: PropTypes.string,
        entityBundle: PropTypes.string,
      })
    }),
  ),
  fieldOfficeHours: PropTypes.string,
  fieldOrganisation: PropTypes.any,
  fieldPositionFree: PropTypes.string,
  fieldRoom: PropTypes.string,
  fieldStatus: PropTypes.array,
  fieldSubject: PropTypes.array,
  fieldSurname: PropTypes.string,
  fieldTitleAfterName: PropTypes.bool,
  path: PropTypes.shape({
    alias: PropTypes.string,
    __typename: PropTypes.string,
  }),
  publishedAt: PropTypes.shape({
    __typename: PropTypes.string,
    value: PropTypes.string,
    publishedAtOrNow: PropTypes.string,
  }),
  entityLabel: PropTypes.string,
  entityUrl: PropTypes.shape({
    __typename: PropTypes.string,
    path: PropTypes.string,
  }),
  fieldBeschreibung: PropTypes.shape({
    __typename: PropTypes.string,
    processed: PropTypes.string,
    value: PropTypes.string,
  }),
  fieldKontakinformationen: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        fieldContactType: PropTypes.string.isRequired,
        fieldWert: PropTypes.shape({
          processed: PropTypes.string.isRequired,
        }),
      }),
    })
  ),
  fieldRolleOderFunktion: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
        entityId: PropTypes.number
      }),
    })
  ),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldBildWCaption: PropTypes.shape({
    __typename: PropTypes.string,
    customCaption: PropTypes.string,
    defaultCaption: PropTypes.bool,
    entity: PropTypes.shape({
      __typename: PropTypes.string,
      fieldCredit: PropTypes.string,
      fieldMediaImage: PropTypes.shape({
        __typename: PropTypes.string,
        alt: PropTypes.string,
        title: PropTypes.string,
        styleSquare: PropTypes.shape({
          __typename: PropTypes.string,
          url: PropTypes.string,
        }),
        style: PropTypes.shape({
          __typename: PropTypes.string,
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserPerson.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPerson));
