import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link32 } from "@carbon/icons-react"
import { encodeUrl } from "../../../../lib/encode-url";
import HeadlineSelectableSize from "../../../headline-selectable-size";

class ParagraphSectionheading extends Component {

  componentDidMount() {
    setTimeout(() => {
      if (window.location.hash && (window.location.hash.replace('#', '') === encodeUrl(this.props.content.fieldHeading))) {
        let anchorLink = document.getElementById(window.location.hash.replace('#', ''));

        if (anchorLink) {
          let scrollPosition = anchorLink.getBoundingClientRect().top + document.documentElement.scrollTop - 250;

          window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
          });
        }
      }
    }, 250);
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-sectionheading": true,
      "sr-only": this.props.content.fieldHide,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-16">
              <HeadlineSelectableSize
                content={this.props.content.fieldHeading}
                size={this.props.content.fieldRenderHeadlineAs}
                customClassNames={"section-heading"}
              />
            </div>
            {this.props.content.fieldSetAnchorLink && (
              <div className="col-16">
                <a
                  className="anchor"
                  href={`#${encodeUrl(this.props.content.fieldHeading)}`}
                >
                  Ankerlink: {encodeUrl(this.props.content.fieldHeading)}
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphSectionheading.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool
  })
};

export default ParagraphSectionheading;
