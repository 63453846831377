import React, {useEffect, useRef} from "react";

const CheckForChildren = ({ classes, children }) => {
  /**
   * This function should hide a wrapper if the wrapper has no children.
   * In most browsers it works, using "&:not(:has(*))", but for example older firefox does not know this function.
   * If the browser does not know it, the function is setting the wrapper to "display: none".
   * It ONLY fires JS when the css pseudo class is not supported.
  */

  const checkForChildrenWrapper = useRef();

  const supportsHasPseudoClass = () => {
    try {
      const style = document.createElement('style');
      document.head.appendChild(style);

      // Attempt to insert a rule using :has
      style.sheet.insertRule(':has(*) {}', 0);

      // If the rule is inserted successfully, the browser supports :has
      document.head.removeChild(style);
      return true;
    } catch (e) {
      // If an error is thrown, the browser does not support :has
      return false;
    }
  }

  useEffect(() => {
    // Usage
    if (!supportsHasPseudoClass()) {
      if (checkForChildrenWrapper.current.innerHTML === "") {
        checkForChildrenWrapper.current.style.display = "none";
      }
    }
  }, []);

  return (
    <div
      className={classes}
      ref={checkForChildrenWrapper}
      data-check-for-children={true}
    >
      {children}
    </div>
  );
}

export default CheckForChildren;