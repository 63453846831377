import React from "react";
import FileSaver from "file-saver";
import {FormattedMessage} from "react-intl";

const createVCS = (e, props) => {

  let contact = {
    lastName: props.content.fieldSurname,
    firstName: props.content.fieldFirstName,
    title: props.content.fieldAcademicTitle,
    email: props.content.fieldMail,
    tel: props.content.fieldTelefon,
    mobile: props.content.fieldMobil,
    note: `${props.content.fieldPositionFree}${props.content.fieldPositionFree ? ' ' : ''}${typeof props.content.fieldSubject !== "undefined" && props.content.fieldSubject.length > 0 ? props.content.fieldSubject?.entity?.entityLabel : ''}`
  }

  //https://codesandbox.io/s/x986yly68w?file=/src/components/VCardFileCreator/index.js:222-230
  // I know, this looks ugly formatted, however it only works like that.
  e.preventDefault();
  var file = new Blob(
    [
      `BEGIN:VCARD
VERSION:3.0
N:${contact.lastName};${contact.firstName}
FN:${contact.firstName} ${contact.lastName}
${contact.title ? `TITLE:${contact.title};` : ''}
${contact.email ? `EMAIL;type=INTERNET;type=pref:${contact.email}` : ''}
${contact.tel ? `TEL;type=MAIN:${contact.tel}` : ''}
${contact.mobile ? `TEL;type=CELL;type=VOICE;type=pref:${contact.mobile}` : ''}
${contact.note ? `NOTE: ${contact.note}` : ''}
END:VCARD`
    ],
    {type: "text/vcard;charset=utf-8"}
  );
  let a = document.createElement("a");

  a.download = `${contact.firstName}${contact.lastName}.vcf`;
  a.href = URL.createObjectURL(file);

  let reader = new FileReader();

  if (navigator.userAgent.match("CriOS")) {
    reader.onloadend = e => {
      window.open(reader.result);
    };
    reader.readAsDataURL(file);
  } else if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
    reader.onload = e => {
      window.location.href = reader.result;
    };
    reader.readAsDataURL(file);
  } else {
    FileSaver.saveAs(
      file,
      `${contact.title ? contact.title : ''}${contact.firstName}${contact.lastName}.vcf`,
      true
    );
  }
}

export const VcardSaveButton = (props) => {
  return(
    <button className={"btn btn-s btn-save"} onClick={(e) => createVCS(e, props)}>
      <FormattedMessage id="contact.save_contact" />
    </button>
  );
}