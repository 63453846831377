import React from "react";
import {FormattedMessage} from "react-intl";
import {self} from "../config";

export const Streamlink = ({ content }) => {
  if (content?.fieldStreamlink) {
    return (
      <a
        className="streamlink info-element"
        href={content.fieldStreamlink.url.path}
        target={"_blank"}
      >
        <img src={`${self}/livestream.svg`} alt={"Livestream Icon"}/>
        <FormattedMessage id="event.stream" />
      </a>
    );
  }
};
