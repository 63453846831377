import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Link, { LinkPropType } from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";
import Image from "../../../image/image";
import VisibilitySensor from "react-visibility-sensor";
import {encodeUrl} from "../../../../lib/encode-url";
import HeadlineSelectableSize from "../../../headline-selectable-size";

class ParagraphClickSuggestions extends Component {

  suggestions = React.createRef();

  onMouseEnter = (e) => {
    let offset = 0,
      element = e;

    if (e.target) {
      element = e.target;
    }
    if  (window.innerWidth > 768) {
      offset = (window.innerWidth * 0.25);
    }

    if (element.offsetWidth > (window.innerWidth - 100)) {
      element.style.marginLeft = `-${element.offsetWidth - (window.innerWidth - 175 - offset)}px`;
    }
  };

  onMouseLeave = e => {
    let element = e;

    if (e.target) {
      element = e.target;
    }
    if (element.offsetWidth > (window.innerWidth - 100)) {
      element.style.marginLeft = 0;
    }
  };

  onChange = (isVisible) => {
    // Timeout, because onchange is faster than setting the class we need.
    setTimeout(() => {
      if (this.suggestions.current && isVisible) {
        let e = this.suggestions.current.querySelector("li.row.active a");

        if (e) {
          // Set new active Element
          this.onMouseEnter(e);
        }
      }
    }, 250);
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-click-suggestions": true,
    });

    return (
      <section className={sectionClassNames} ref={this.suggestions}>
        <div className="container">
          <div className="row">
            <div className="col-16">
              <HeadlineSelectableSize
                content={this.props.content.fieldHeading}
                size={"h3"}
                customClassNames={"section-heading"}
              />
            </div>
          </div>
        </div>
        <ul className="container fluid">
          {this.props.content.fieldLinkInternalExternal.map((item, index) => {
            let internalLink = item?.entity?.fieldInternalContent?.entity?.entityUrl?.path;

            if (internalLink && item?.entity.fieldAnchorLink) {
              internalLink = `${internalLink}#${item.entity.fieldAnchorLink}`;
            }

            if (item && item?.entity) {
              return (
                <React.Fragment key={index}>
                  <VisibilitySensor
                    partialVisibility={false}
                    onChange={this.onChange}
                    active={typeof window !== "undefined" && window.innerWidth < 768}
                    offset={{
                      top: typeof window !== "undefined" ? (window.innerHeight / 2 - 50) : 300,
                      bottom: typeof window !== "undefined" ? (window.innerHeight / 2 - 50) : 300
                    }}
                  >
                    {({isVisible}) =>
                      <>
                        {item?.entity.entityBundle === "external_link" &&
                          <li className={`row ${isVisible ? "active" : "inactive"}`}>
                            <div className="col-wrapper col-16">
                              <a
                                href={item.entity.fieldExternalLink.url.path}
                                target={"_blank"}
                                onMouseEnter={(e) => this.onMouseEnter(e.target)}
                                onMouseLeave={(e) => this.onMouseLeave(e.target)}
                                className="external-link"
                              >
                                {item.entity.fieldExternalLink.title}
                              </a>
                            </div>
                          </li>
                        }
                        {item?.entity.entityBundle === "internal_link" && item.entity.fieldInternalContent?.entity &&
                          <li className={`row ${isVisible ? "active" : "inactive"}`}>
                            <div className="col-wrapper col-16">
                              <ErrorBoundary>
                                <a
                                  href={internalLink}
                                  onMouseEnter={e => this.onMouseEnter(e)}
                                  onMouseLeave={e => this.onMouseLeave(e)}
                                  className="internal-link"
                                >
                                  {item.entity.fieldAlternativeLabel ? (
                                    <>
                                      {item.entity.fieldAlternativeLabel}
                                    </>
                                  ) : (
                                    <>
                                      {item.entity.fieldInternalContent.entity.entityLabel}
                                    </>
                                  )}
                                </a>
                              </ErrorBoundary>

                              {(item.entity.fieldInternalContent?.entity?.fieldTeaserbild || item.entity.fieldInternalContent.entity?.fieldBild) &&
                                <div className="hover-image">
                                  {item.entity.fieldInternalContent.entity.fieldTeaserbild &&
                                    <Image
                                      data={item.entity.fieldInternalContent.entity.fieldTeaserbild.entity.fieldMediaImage}
                                      credit={item.entity.fieldInternalContent.entity.fieldTeaserbild.entity.fieldCredit}
                                    />
                                  }
                                  {item.entity.fieldInternalContent.entity?.fieldBild &&
                                    <Image
                                      data={item.entity.fieldInternalContent.entity.fieldBild.entity.fieldMediaImage}
                                      credit={item.entity.fieldInternalContent.entity.fieldBild.entity.fieldCredit}
                                    />
                                  }
                                </div>
                              }
                            </div>
                          </li>
                        }
                      </>
                    }
                  </VisibilitySensor>
                </React.Fragment>
              );
            }
          })}
        </ul>
      </section>
    );
  }
}

ParagraphClickSuggestions.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldSetAnchorLink: PropTypes.bool,
    fieldLinkInternalExternal: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          entityBundle: PropTypes.string,
          fieldAddAnchorLink: PropTypes.bool,
          fieldAlternativeLabel: PropTypes.string,
          fieldAnchorLink: PropTypes.any,
          fieldExternalLink: PropTypes.shape({
            __typename: PropTypes.string,
            title: PropTypes.string,
            url: PropTypes.shape({
              __typename: PropTypes.string,
              path: PropTypes.string,
              routed: PropTypes.bool,
            }),
          }),
          fieldInternalContent: PropTypes.shape({
            __typename: PropTypes.string,
            entity: PropTypes.shape({
              __typename: PropTypes.string,
              entityBundle: PropTypes.string,
              entityLabel: PropTypes.string,
              entityUrl: PropTypes.shape({
                __typename: PropTypes.string,
                path: PropTypes.string,
              }),
              fieldTeaserbild: PropTypes.shape({
                __typename: PropTypes.string,
                entity: PropTypes.shape({
                  __typename: PropTypes.string,
                  fieldMediaImage: PropTypes.shape({
                    alt: PropTypes.string,
                    title: PropTypes.string,
                    style: PropTypes.shape({
                      url: PropTypes.string,
                    }),
                    bigPicture: PropTypes.shape({
                      __typename: PropTypes.string,
                      url: PropTypes.string,
                    }),
                  }),
                }),
              }),
            }),
          }),
        }),
      }),
    ),
    //fieldLinks: PropTypes.arrayOf(LinkPropType),
  }),
};

export default ParagraphClickSuggestions;
