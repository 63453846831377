const adminAppAction = (adminApp) => ({
    adminApp,
    type: "ADMIN_APP",
  }),
  isMobileAction = (isMobile) => ({
    isMobile,
    type: "IS_MOBILE",
  }),
  mainSettingsAction = (mainSettings) => ({
    mainSettings,
    type: "MAIN_SETTINGS",
  }),
  searchOpenAction = searchOpen => ({
    searchOpen,
    type: 'SEARCH_OPEN'
  }),
  menuOpenAction = menuOpen => ({
    menuOpen,
    type: 'MENU_OPEN'
  }),
  menuCtaAction = menuCta => ({
    menuCta,
    type: 'MENU_CTA'
  }),
  pagerFullPagesAction = (config) => ({
    config,
    type: "PAGER_FULL_PAGE",
  }),
  pagerFullPageContextAction = (id) => ({
    id,
    type: "PAGER_FULL_PAGE_CONTEXT_ID",
  }),
  microSiteAction = (microSite) => ({
    microSite,
    type: "MICROSITE",
  }),
  microSiteFrontPageAction = (microSiteFrontPage) => ({
    microSiteFrontPage,
    type: "MICROSITE_FRONTPAGE",
  });

export {
  adminAppAction,
  isMobileAction,
  mainSettingsAction,
  searchOpenAction,
  menuOpenAction,
  menuCtaAction,
  pagerFullPagesAction,
  pagerFullPageContextAction,
  microSiteFrontPageAction,
  microSiteAction,
};
