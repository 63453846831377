import React, { useState, useEffect } from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

export const EventType = (props) => {
  // Hide this element if Livestream Link is set and Livestream Event tyoe is used
  const isStreamHasLivestreamLink = props.content.fieldEventType?.name === "Livestream" && props.content.fieldStreamlink;

  if (props.content.fieldEventType && props.content.fieldEventType.entity && props.content.fieldEventType.entity.fieldIcon && !isStreamHasLivestreamLink) {
    return(
      <span className="event-type info-element">
        {props.content.fieldEventType.entity.fieldIcon &&
          <img src={props.content.fieldEventType.entity.fieldIcon.entity.fieldMediaImage2.url}/>
        }
        {props.content.fieldEventType.entity.name}
      </span>
    );
  }
};