import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";
import ContentBase from "../../content-base";
import Arrow from "../../../icons/arrow";

class ParagraphAkkordionText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-akkordion-text": true,
    });

    return (
      <section className={sectionClassNames}>
        <Accordion
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
        >
          {this.props.content.fieldAbschnitte.map((item, index) => (
            <ErrorBoundary key={index}>
              <div className="container inside-accordion">
                <div className="row">
                  <div className="col-16 col-md-12 offset-md-2 accordion-element">
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h3>
                            {item.entity.fieldTitel}
                          </h3>
                          <Arrow direction={"down"}/>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {item.entity.fieldCollapseModules && (
                          <ContentBase
                            content={item.entity.fieldCollapseModules}
                            viewMode={"collapse"}
                          />
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          ))}
        </Accordion>
      </section>
    );
  }
}

ParagraphAkkordionText.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTitel: PropTypes.string,
          fieldCollapseModules: PropTypes.arrayOf(
            PropTypes.shape({
              entity: PropTypes.shape({
                entityBundle: PropTypes.string,
                fieldText: PropTypes.shape({
                  processed: PropTypes.string,
                }),
              }),
            }),
          ),
        }),
      }),
    ),
  }),
};

export default ParagraphAkkordionText;
