import React, { useState, useEffect } from "react";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import {self} from "../config";
import getEncodedIcalUrl from "../lib/get-encoded-ical-url";

export const AddToCalendar = (props) => {

 const eventPassed = moment().diff(moment.unix(props.content.fieldDate?.value)) > 0;

 let location = "";

 if (props.content?.fieldOrt) {
  location = props.content.fieldOrt;
 }

 if (props.content?.fieldAddress?.addressLine1) {
  location = `${location} - ${props.content.fieldAddress.addressLine1}`;
 }

 if (props.content?.fieldAddress?.addressLine2) {
  location = `${location} ${props.content.fieldAddress.addressLine2}`;
 }

 if (props.content?.fieldAddress?.postalCode) {
  location = `${location}, ${props.content.fieldAddress.postalCode}`;
 }

 if (props.content?.fieldAddress?.locality) {
  location = `${location}, ${props.content.fieldAddress.locality}`;
 }

 let event = {
  title: props.content?.entityLabel,
  description: props.content.fieldText ? props.content.fieldText.processed : "",
  location: location,
  startDate: moment(props.content.fieldDate?.startTime).format("YYYY-MM-DD"),
  startTime: moment(props.content.fieldDate?.startTime).format("HH:MM"),
  endDate: props.content.fieldDate?.endTime ? moment(props.content.fieldDate?.endTime).format("YYYY-MM-DD") : "",
  endTime: props.content.fieldDate?.endTime ? moment(props.content.fieldDate?.endTime).format("HH:MM") : ""
 }


 if (!eventPassed) {
  return(
    <div className="add-to-calendar info-element">
     <img src={`${self}/checkmark.svg`} alt={"Calendar Icon"}/>
     <a
       href={getEncodedIcalUrl(event)}
     >
      <FormattedMessage id={"event.save_date"}/>
     </a>
    </div>
  );
 }
};
