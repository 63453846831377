import React, { Component } from "react";
import PropTypes from "prop-types";
import { Search, Button } from "carbon-components-react";
import {self} from "../../../../../config";
import {FormattedMessage, injectIntl} from "react-intl";

class ComponentExtendedTeaserOverviewSearch extends Component {
  state = {};

  search = React.createRef();

  render() {

    const { intl } = this.props;

    return (
      <section className="search">
        <div className="container">
          <div className="row">
            <div className="col col-md-12 input-col">
              <Search
                ref={this.search}
                id="search-1"
                placeHolderText={`${intl.formatMessage({ id: 'person.search' })}...`}
                size="xl"
                onChange={(event) =>
                  typeof event.target.value === "undefined" ||
                  event.target.value.length < 3
                    ? this.props.changeActiveSearch()
                    : this.props.changeActiveSearch(
                        this.search.current.input.value
                      )
                }
                labelText="Suche..."
              />
            </div>
            <div className="col col-md-2 icon-col d-md-flex">
              <Button
                onClick={() =>
                  this.props.changeActiveSearch(this.search.current.input.value)
                }
              >
                <span className="sr-only">
                  <FormattedMessage id={"person.search"}/>
                </span>
                <img className="icon search" src={`${self}/search.svg`} alt={"Icon Suche"}/>
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ComponentExtendedTeaserOverviewSearch.propTypes = {
  changeActiveSearch: PropTypes.func.isRequired,
};

export default injectIntl(ComponentExtendedTeaserOverviewSearch);
