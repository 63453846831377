import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import QuoteContent from "./quote-content";

class ParagraphZitat extends Component {
  render() {

    const sectionClassNames = classNames({
      "paragraph paragraph-zitat": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <QuoteContent
            content={this.props.content}
            inCarousel={false}
          />
        </div>
      </section>
    );
  }
}

ParagraphZitat.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
    fieldZitat: PropTypes.string.isRequired,
    fieldQuelle: PropTypes.string.isRequired,
  }),
};

ParagraphZitat.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default ParagraphZitat;
