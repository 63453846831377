import React, {useEffect, useRef} from "react";
import useScreenSize from "../hooks/use-screen-size";

const UseScreenSize = ({ size, operator, children }) => {
 if (operator) {
   if (useScreenSize(size)) {
     return (
       <>
         {children}
       </>
     );
   }
 } else {
   if (!useScreenSize(size)) {
     return (
       <>
         {children}
       </>
     );
   }
 }
}

export default UseScreenSize;