import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MobileView, BrowserView } from 'react-device-detect';

import LoadingIndicator from "../general-components/loading-indicator";
import FooterSitemap from "./components/footer-sitemap";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  isMobile: reduxStore.appStore.isMobile
});

const LegalMenu = ({ menu }) => {
  if (menu) {
    return (
      <nav className="legal-menu">
        <ul>
          {menu.links.map((item, index) => (
            <li key={index}>
              <Link to={item.url.path}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}

/**
 * @todo Add title attribute to links.
 */
class PageFooter extends Component {

  render() {
    return (
      <div className="container">
        {this.props.footerConfig && this.props.menu && (
          <>
            {this.props.footerConfig.fieldFooterSitemapZeigen && (
              <FooterSitemap
                menu={
                  this.props.footerConfig.fieldMenue
                    ? this.props.footerConfig.fieldMenue.targetId
                    : "main"
                }
              />
            )}
            <div className="row">
              <div className="col-16 col-lg-7 info-col">
                <div className="social-media">
                  {this.props.footerConfig?.fieldSocialMediaText &&
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: this.props.footerConfig.fieldSocialMediaText.processed }}
                    />
                  }
                  {this.props.footerConfig?.fieldFacebook && (
                    <a
                      className="facebook btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig.fieldFacebook.url.path}
                    >
                      Facebook
                    </a>
                  )}
                  {this.props.footerConfig?.fieldYoutube && (
                    <a
                      className="youtube btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig?.fieldYoutube.url.path}
                    >
                      YouTube
                    </a>
                  )}
                  {this.props.footerConfig?.fieldFlickr && (
                    <a
                      className="flick btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig?.fieldFlickr.url.path}
                    >
                      Flickr
                    </a>
                  )}
                  {this.props.footerConfig?.fieldInstagram && (
                    <a
                      className="instagram btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig?.fieldInstagram.url.path}
                    >
                      Instagram
                    </a>
                  )}
                  {this.props.footerConfig?.fieldVimeo && (
                    <a
                      className="vimeo btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig?.fieldVimeo.url.path}
                    >
                      Vimeo
                    </a>
                  )}
                  {this.props.footerConfig?.fieldLinkedin && (
                    <a
                      className="linkedin btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig?.fieldLinkedin.url.path}
                    >
                      LinkedIn
                    </a>
                  )}
                  {this.props.footerConfig?.fieldPinterest && (
                    <a
                      className="pinterest btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig?.fieldPinterest.url.path}
                    >
                      Pinterest
                    </a>
                  )}
                  {this.props.footerConfig?.fieldTwitter && (
                    <a
                      className="twitter btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig?.fieldTwitter.url.path}
                    >
                      X
                    </a>
                  )}
                  {this.props.footerConfig?.fieldXing && (
                    <a
                      className="xing btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig?.fieldXing.url.path}
                    >
                      Xing
                    </a>
                  )}
                  {this.props.footerConfig?.fieldTumblr && (
                    <a
                      className="tumblr btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.footerConfig?.fieldTumblr.url.path}
                    >
                      Tumblr
                    </a>
                  )}
                </div>
                {this.props.menu &&
                  <nav className="footer-menu">
                    <ul>
                      {this.props.menu?.links.map((item, index) => (
                        <li key={index}>
                          {item.url.routed ? (
                            <Link
                              to={item.url.path}
                              className="additional-link"
                            >
                              {item.label}
                            </Link>
                          ) : (
                            <a
                              href={item.url.path}
                              className="additional-link"
                              target={"_blank"}
                            >
                              {item.label}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                }

                {!this.props.isMobile &&
                  <LegalMenu
                    menu={this.props.legalMenu}
                  />
                }
              </div>
              <div className="col-16 col-lg-8 offset-lg-1 text-col">
                {this.props.footerConfig?.fieldFulltext &&
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: this.props.footerConfig?.fieldFulltext.processed }}
                  />
                }

                {this.props.footerConfig?.fieldAdditionalLink &&
                  <>
                    {this.props.footerConfig?.fieldAdditionalLink.url.routed ? (
                      <a
                        href={this.props.footerConfig?.fieldAdditionalLink.url.path}
                        className="additional-link internal-link arrow-after"
                        target={"_blank"}
                      >
                        {this.props.footerConfig?.fieldAdditionalLink.title}
                      </a>
                    ) : (
                      <Link
                        to={this.props.footerConfig?.fieldAdditionalLink.url.path}
                        className="additional-link internal-link arrow-after"
                      >
                        {this.props.footerConfig?.fieldAdditionalLink.title}
                      </Link>
                    )}
                  </>
                }

                {this.props.isMobile &&
                  <LegalMenu
                    menu={this.props.legalMenu}
                  />
                }
              </div>
            </div>
          </>
        )}
      </div>
    );

    return <LoadingIndicator />;
  }
}

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  footerConfig: PropTypes.shape({
    fieldFooterSitemapZeigen: PropTypes.bool,
    fieldMenue: PropTypes.shape({
      targetId: PropTypes.string,
    }),
    fieldFacebook: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTwitter: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
  menu: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.shape({
          path: PropTypes.string,
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(PageFooter);
