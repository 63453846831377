import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";

import teaserNodeQueryEventTagFiltered from "../../../../teaser-base/queries/teaser-node-query-event-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserEvent, {
  teaserEventPropTypes,
} from "../../../../teaser-base/event/teaser-event";
import EventListBasic from "./event-list-basic";
import ParagraphKalenderAbschnitt from "../../kalender-abschnitt/paragraph-kalender-abschnitt";

moment.locale("de");

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  currentLanguage: reduxStore.i18n.currentLanguage,
});

const TeaserEventList = (props) => {
  useEffect(() => {
    // Send query Data to parent
    if (props.onQueryLoaded && props.nodes?.nodeQuery?.entities) {
      props.onQueryLoaded(props.nodes.nodeQuery.count);
    }
  }, [props.nodes]);

  return (
    <>
      {props.nodesConfig === "Manuell" ? (
        <>
          {props.miniCalendarView ? (
            <ParagraphKalenderAbschnitt
              manualNodes={props.manualNodes}
              overviewLink={props.overviewLink}
              title={props.title}
              miniCalendarView={true}
              futureEventsOnly={props.futureEventsOnly}
            />
          ) : (
            <EventListBasic
              nodes={props.manualNodes}
            />
          )}
        </>
      ) : (
        <>
          {props.nodes.nodeQuery &&
          props.nodes.nodeQuery.entities &&
          props.nodes.nodeQuery.entities.length > 0 ? (
            <>
              {props.miniCalendarView ? (
                <ParagraphKalenderAbschnitt
                  automatic={props.fieldAutmatischOderManuell}
                  overviewLink={props.overviewLink}
                  title={props.title}
                  overWriteNodes={props.nodes}
                  miniCalendarView={true}
                  futureEventsOnly={props.futureEventsOnly}
                />
              ) : (
                <EventListBasic
                  nodes={props.nodes.nodeQuery.entities}
                />
              )}
            </>
          ) : (
            <LoadingIndicator />
          )}
        </>
      )}
    </>
  );
};

TeaserEventList.propTypes = {
  count: PropTypes.number,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: teaserEventPropTypes,
    })
  ),
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["veranstaltung"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  location: PropTypes.object.isRequired,
  nodes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryEventTagFiltered, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell",
    options: (props) => ({
      variables: {
        date: moment().unix().toString(),
        dateFilterEnabled: props.futureEventsOnly,
        limit: props.miniCalendarView ? 24 : 12,
        type: [props.type],
        tags: props.tags.map((item) => item.targetId.toString()),
        filterTagEnabled: props.tags.length > 0,
        eventSeriesFilterEnabled: !!props.eventSeries,
        eventSeriesFilter: props.eventSeries ? props.eventSeries : "",
        eventId: props.eventId ? props.eventId : "",
        language: props.currentLanguage.toUpperCase(),
        microSiteFilterEnabled: true,
        microSiteFilter: props.microSite ? props.microSite : "hmdk",
      },
    }),
  })(withRouter(TeaserEventList))
);
