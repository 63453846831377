import React, { useState } from "react";
import PropTypes from "prop-types";
import {encodeUrl} from "../../../../../lib/encode-url";
import { useHistory } from "react-router-dom";
import groupBy from "lodash.groupby";

// Create the overview context.
export const OverviewContext = React.createContext({});

// The OverviewProvider makes the context store accessible to children.
const OverviewProvider = ({ children, taxonomies }) => {
  const history = useHistory();

  const [taxonomyFilter, setTaxonomyFilter] = useState({});
  const [groupedTaxonomies, setGroupedTaxonomies] = useState({});
  const [preparedTaxonomies, setPreparedTaxonomies] = useState({});

  const selectCategory = (child, parent) => {
    history.push({
      search: `?category=${encodeUrl(child.name)}&id=${child.tid}`
    });

    setTaxonomyFilter(child);
  }

  const getPreparedTaxonomies = (taxonomies) => {
    const kvTerms = taxonomies && !taxonomies.loading && taxonomies.taxonomyTermQuery.entities;

    if (kvTerms) {
      // unpreparedTaxonomies = all Taxononmies, grouped by ther parent Taxonomy.
      let unpreparedTaxonomies = groupBy(kvTerms, item => item.parent && item.parent[0] && item.parent[0].targetId),
        preparedTaxonomies = {};

      // Submit Basically Grouped Taxonomies to use them later to get deeper Taxonomy-Structure
      setGroupedTaxonomies(unpreparedTaxonomies);

      // Get first Level Taxonomies.
      unpreparedTaxonomies[0] && unpreparedTaxonomies[0].map((taxonomy) => {
        // Create new Object to have Taxonomy with its children
        let preparedTaxonomy = Object.assign({children: {}}, taxonomy);

        // Add Children to taxonomy.
        if (!!unpreparedTaxonomies[taxonomy.tid]) {
          preparedTaxonomy.children = unpreparedTaxonomies[taxonomy.tid];
        }

        //Set Object with Name as ID.
        preparedTaxonomies[taxonomy.name] = preparedTaxonomy;
      });

      // Set correctly structured Taxonomies to State.
      setPreparedTaxonomies(preparedTaxonomies);
      setPreparedTaxonomies(preparedTaxonomies);
    }
  }

  // The context value provides all necessary values and functions.
  const value = {
    taxonomies: taxonomies,
    groupedTaxonomies,
    taxonomyFilter,
    setTaxonomyFilter,
    selectCategory,
    preparedTaxonomies,
    getPreparedTaxonomies
  };

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};

OverviewProvider.propTypes = {
  children: PropTypes.node
};

export default OverviewProvider;
