import React from "react";
import {FormattedMessage} from "react-intl";

export const ClassCooperation = ({ content }) => {
  if (content?.fieldClassCooperation) {
    return (
      <div className="class-cooperation">
        {content.fieldClassCooperation}
      </div>
    );
  }
};
