import React, { useState, useEffect } from "react";
import FieldTime from "../../date-time/field-time";
import {FormattedMessage} from "react-intl";
import {AddToCalendar} from "../../../event/add-to-calendar";
import moment from "moment";
import PropTypes from "prop-types";
import EventDate from "../../../event/event-date";

export const PinboardContentTeaser = (props) => {

  let content = props.content?.entity || props.content,
    eventPassed = moment().diff(moment.unix(content.fieldDate?.value).local()) > 0;

  if (content && content.status) {
    return(
      <article className="node node-teaser teaser-pinboard-content">
        <div className="row">
          <div className="text-col col">
            {content.title &&
              <h3 className="title">
                {content.title}
              </h3>
            }
            {content.fieldText &&
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: content.fieldText.processed }}
              />
            }
          </div>
          <div className="date-col col">
            <EventDate content={content}/>
          </div>
          <div className="action-col col">
            {content.fieldContactType && content.fieldMail && content.fieldContactType === "send-mail" &&
              <button className="btn solid action send-mail">
                <a href={`mailto:${content.fieldMail}`} target={"_blank"}>
                  <FormattedMessage id="contact.write_email" />
                </a>
              </button>
            }
            {content.fieldContactType && content.fieldDate && content.fieldContactType === "save-date" && !eventPassed &&
              <button className="btn solid action save-date">
                <AddToCalendar content={content}/>
              </button>
            }
            {content.fieldFile &&
              <button className="btn solid btn-s btn-save">
                Pdf
              </button>
            }
          </div>
        </div>
      </article>
    );
  }
};
PinboardContentTeaser.PropTypes = {
  __typename: PropTypes.string,
  entityId: PropTypes.string,
  entityBundle: PropTypes.string,
  entityLabel: PropTypes.string,
  fieldContactType: PropTypes.string,
  fieldMail: PropTypes.string,
  title: PropTypes.string,
  fieldDate: PropTypes.shape({
    value: PropTypes.number,
    __typename: PropTypes.string,
    endTime: PropTypes.string,
    startTime: PropTypes.string,
  }),
  fieldText: PropTypes.shape({
    processed: PropTypes.string,
    __typename: PropTypes.string,
  }),
  fieldFile: PropTypes.shape({
    __typename: PropTypes.string,
    entity: PropTypes.shape({
      __typename: PropTypes.string,
      fieldBeschreibung: PropTypes.shape({
        value: PropTypes.string,
        processed: PropTypes.string,
      }),
      entityLabel: PropTypes.string,
      fieldMediaFile: PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          url: PropTypes.string,
        }),
      }),
      fieldVorschaubild: PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        })
      }),
    }),
  }),
}
export default PinboardContentTeaser;