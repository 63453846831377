import GalleryArrow from "../../../../icons/gallery-arrow";
import React from "react";

export const sliderSettingsStandard = {
  adaptiveHeight: false,
  arrows: true,
  centerMode: true,
  dots: false,
  infinite: true,
  slidesToShow: 3.66,
  slidesToScroll: 1,
  touchMove: false,
  nextArrow: <GalleryArrow type={"next"} followMouse={false}/>,
  prevArrow: <GalleryArrow type={"previous"} followMouse={false}/>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.25,
        touchMove: true,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 1.66,
        touchMove: true,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        touchMove: true,
      },
    },
  ],
};

export const sliderSettingsArtists = {
  adaptiveHeight: false,
  arrows: true,
  centerMode: false,
  dots: false,
  infinite: false,
  slidesToShow: 1.33,
  slidesToScroll: 1,
  touchMove: true,
  nextArrow: <GalleryArrow type={"next"} followMouse={false}/>,
  prevArrow: <GalleryArrow type={"previous"} followMouse={false}/>,
};

export default { sliderSettingsStandard, sliderSettingsArtists };