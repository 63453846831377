import React from "react";
import PropTypes from "prop-types";
import { hyphenateSync as hyphenate } from "hyphen/de";

const CustomHyphenation = ({ children, length }) => {

  return <>{hyphenate(children, { minWordLength: length })}</>;
};

CustomHyphenation.propTypes = {
  children: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
};

export default CustomHyphenation;