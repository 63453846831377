import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import Slider from "react-slick";
import { connect } from "react-redux";

import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import TeaserArtistInSlider, {
  teaserArtistPropTypes,
} from "../../../../teaser-base/artist/teaser-artist-in-slider";
import { pagerFullPagesAction } from "../../../../../app-actions";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import {
  sliderSettingsStandard,
  sliderSettingsArtists,
} from "./slider-settings";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite,
});

class ComponentTeaserlistCarousel extends React.Component {
  state = {
    manualNodes: [],
    automaticNodes: [],
  };

  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes.nodeQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  prepareNodes = () => {
    let manualNodes = this.props.manualNodes,
      automaticNodes = this.props.nodes?.nodeQuery?.entities
        ? this.props.nodes.nodeQuery.entities
        : [],
      carouselMinAmmount = 5;

    // duplicate content if it is less than mininum elements
    if (automaticNodes.length > 0 && automaticNodes.length <= 4) {
      for (let i = 0; i < carouselMinAmmount - automaticNodes.length; i++) {
        automaticNodes = [...automaticNodes, ...automaticNodes];
      }
    }

    if (manualNodes.length <= 4 && manualNodes.length !== 0) {
      for (let i = 0; i < manualNodes - manualNodes.length; i++) {
        manualNodes = [...manualNodes, ...manualNodes];
      }
    }

    this.setState({
      manualNodes,
      automaticNodes,
    });
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
    this.prepareNodes();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.nodes && (prevProps.nodes !== this.props.nodes)
    ) {
      this.pushPagerFullPageConfig();
      this.prepareNodes();
    }
  }

  render() {
    if (this.props.nodes && this.props.nodes.loading) {
      return false;
    }

    const sliderSettings =
      this.props.type === "artist"
        ? sliderSettingsArtists
        : sliderSettingsStandard;

    return (
      <>
        {this.props.nodesConfig === "Manuell" ? (
          <Slider {...sliderSettings}>
            {this.state.manualNodes &&
              this.state.manualNodes.map((item, index) => (
                <React.Fragment key={index}>
                  {(() => {
                    switch (item.entity?.entityBundle) {
                      case "news":
                        return (
                          <TeaserNews
                            item={item.entity}
                            index={index}
                            pagerFullPage={this.props.pagerFullPage}
                          />
                        );
                      case "person":
                        return (
                          <TeaserPerson
                            item={item.entity}
                            index={index}
                            pagerFullPage={this.props.pagerFullPage}
                          />
                        );
                      case "artist":
                        return (
                          <TeaserArtistInSlider
                            item={item}
                            index={index}
                            pagerFullPage={this.props.pagerFullPage}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </React.Fragment>
              ))}
          </Slider>
        ) : (
          <>
            {this.props.nodes.nodeQuery &&
            this.state.automaticNodes?.length > 0 ? (
              <>
                <Slider {...sliderSettings}>
                  {this.state.automaticNodes.map((item, index) => (
                    <React.Fragment key={index}>
                      {(() => {
                        switch (item.entityBundle) {
                          case "news":
                            return (
                              <TeaserNews
                                item={item}
                                pagerFullPage={this.props.pagerFullPage}
                              />
                            );
                          case "person":
                            return (
                              <TeaserPerson
                                item={item}
                                index={index}
                                pagerFullPage={this.props.pagerFullPage}
                              />
                            );
                          case "artist":
                            return (
                              <TeaserArtistInSlider
                                item={item}
                                index={index}
                                pagerFullPage={this.props.pagerFullPage}
                              />
                            );
                          default:
                            return null;
                        }
                      })()}
                    </React.Fragment>
                  ))}
                </Slider>
              </>
            ) : (
              <LoadingIndicator />
            )}
          </>
        )}
      </>
    );
  }
}

ComponentTeaserlistCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.oneOfType([
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserGeneralPropTypes,
        teaserArtistPropTypes,
      ]),
    })
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  nodes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

const getSortField = (type) => {
  let sortField = "created";

  switch (type) {
    case "person":
      {
        sortField = "field_surname";
      }
      break;
    case "news":
      {
        sortField = "published_at";
      }
      break;
  }

  return sortField;
};

const getSortDirection = (type) => {
  let sortField = "DESC";

  switch (type) {
    case "person":
      {
        sortField = "ASC";
      }
      break;
    case "news":
      {
        sortField = "DESC";
      }
      break;
  }

  return sortField;
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell",
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "all" ? ["news", "person"] : [props.type],
        tag: props.tags?.map((item) => item.targetId.toString()),
        filterTagEnabled: props.tags && props.tags.length > 0,
        subject: props.subject?.map((item) => item.targetId.toString()),
        filterSubjectEnabled: props.subject && props.subject.length > 0,
        faculty: props.faculty?.map((item) => item.targetId.toString()),
        filterFacultyEnabled: props.faculty && props.faculty.length > 0,
        personStatus: props.personStatus?.map((item) =>
          item.targetId.toString()
        ),
        filterPersonStatusEnabled:
          props.personStatus && props.personStatus.length > 0,
        institute: props.institute?.map((item) => item.targetId.toString()),
        filterInstituteEnabled: props.institute && props.institute.length > 0,
        organisation: props.organisation?.map((item) =>
          item.targetId.toString()
        ),
        filterOrganisationEnabled:
          props.organisation && props.organisation.length > 0,
        function: props.function?.map((item) => item.targetId.toString()),
        filterFunctionEnabled: props.function && props.function.length > 0,
        microSiteFilterEnabled: props.type !== "lecture",
        microSiteFilter: props.microSite ? props.microSite : "hmdk",
        language: props.currentLanguage.toUpperCase(),
        sortField: getSortField(props.type),
        sortDirection: getSortDirection(props.type),
      },
    }),
  })(ComponentTeaserlistCarousel)
);
