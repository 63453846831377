import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserGeneral from "../../../teaser-base/general/teaser-general";
import ErrorBoundary from "../../../../error-boundary";
import Slider from "react-slick";
import GalleryArrow from "../../../icons/gallery-arrow";
import VisibilitySensor from "react-visibility-sensor";
import {encodeUrl} from "../../../../lib/encode-url";
import {connect} from "react-redux";

const mapStateToProps = (reduxStore) => ({
  isMobile: reduxStore.appStore.isMobile
});


class ParagraphDossier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 0
    };
  }


  render() {
    const slidesToShow = 3.75,
      slidesToShowMobile = 1.5;

    const sliderSettings = {
      dots: false,
      slidesToShow,
      variableWidth: false,
      slidesToScroll: 1,
      focusOnSelect: false,
      touchMove: false,
      adaptiveHeight: false,
      infinite: false,
      nextArrow: <GalleryArrow type={"next"} followMouse={false}/>,
      prevArrow: <GalleryArrow type={"previous"} followMouse={false}/>,
      beforeChange: (current, next) => {
        this.setState({ slideIndex: next });
      },
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: slidesToShowMobile,
            touchMove: true,
          },
        }
      ],
    }

    const sectionClassNames = classNames({
      "paragraph paragraph-dossier": true,
      "cropped-images": this.props.content.fieldCropImage,
      "standard": !this.props.content.fieldCropImage,
      "no-prev": this.state.slideIndex === 0,
      "no-next": (this.props.content.fieldReferencedTeasers?.length < (this.state.slideIndex + sliderSettings.slidesToShow)) || ((sliderSettings.slidesToShow - this.state.slideIndex) === (this.props.isMobile ? 0.25 : 1.5))
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldImage !== null && (
          <div className="background-image" style={customStyle} />
        )}
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-16 col-md-10 offset-md-1">
                <h2 id={encodeUrl(this.props.content.fieldHeading)}>{this.props.content.fieldHeading}</h2>
              </div>
            </div>
          </div>
        )}

        <VisibilitySensor
          partialVisibility={true}
        >
          {({isVisible}) =>
            <div className={`slider-wrapper ${isVisible ? 'visible' : 'invisible'}`}>
              <Slider {...sliderSettings}>
                {typeof this.props.content.fieldReferencedTeasers && this.props.content.fieldReferencedTeasers.map((item, index) =>
                  <div
                    className="teaser-slide"
                    data-active-slide={this.state.slideIndex === index}
                    data-active-slide-plus-1={this.state.slideIndex === index - 1}
                    data-active-slide-plus-2={this.state.slideIndex === index - 2}
                    key={index}
                  >
                    <ErrorBoundary>
                      {item && item.entity.fieldInternalContent && item.entity.fieldInternalContent?.entity &&
                        <TeaserGeneral
                          item={item.entity.fieldInternalContent.entity}
                          alternativeLabel={item.entity.fieldAlternativeLabel}
                          cropImage={this.props.content.fieldCropImage}
                        />
                      }
                    </ErrorBoundary>
                  </div>
                )}
              </Slider>
            </div>
          }
        </VisibilitySensor>
      </section>
    );
  }
}

ParagraphDossier.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
    fieldCropImage: PropTypes.bool,
    fieldReferencedTeasers: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          entityBundle: PropTypes.string,
          fieldAlternativeLabel: PropTypes.string,
          fieldInternalContent: PropTypes.shape({
            entity: PropTypes.shape({
              entityId: PropTypes.string,
              entityBundle: PropTypes.string,
              entityUrl: PropTypes.shape({
                path: PropTypes.string,
                alias: PropTypes.string,
              }),
              path: PropTypes.shape({
                url: PropTypes.string,
                alisa: PropTypes.string,
              }),
              fieldTeaserBild: PropTypes.shape({
                __typename: PropTypes.string,
                entity: PropTypes.shape({
                  __typename: PropTypes.string,
                  fieldMediaImage: PropTypes.shape({
                    alt: PropTypes.string,
                    square: PropTypes.shape({
                      url: PropTypes.string,
                      __typename: PropTypes.string,
                    }),
                    bigPicture: PropTypes.shape({
                      url: PropTypes.string,
                      __typename: PropTypes.string,
                    }),
                    title: PropTypes.string,
                    style: PropTypes.shape({
                      url: PropTypes.string,
                    }),
                  }),
                }),
              }),
            }),
          }),
        }),
      })
    ),
  }),
};


export default connect(mapStateToProps)(ParagraphDossier);
