import React from "react";
import Image from "../general-components/image/image";

export const PersonName = (props) => {
  return(
    <div className="person-image-wrapper">
      {props.content.fieldBildWCaption ? (
        <Image
          data={props.content.fieldBildWCaption.entity.fieldMediaImage}
          styleSquare={true}
          nodeTitle={props.content.title}
        />
      ) : (
        <div className="initials-wrapper">
          <div className="initials">
            {props.content.fieldFirstName &&
              <>
                {props.content.fieldFirstName.substring(0, 1)}
              </>
            }
            {props.content.fieldSurname &&
              <>
                {props.content.fieldSurname.substring(0, 1)}
              </>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonName;
