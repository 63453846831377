import React from "react";
import ContentBase from "../../content-base";
import moment from "moment";

export const ParagraphScheduledModule = (props) => {
  let showModule = false;

  // Check if "Show From" Value is passed
  if (props.content.fieldShowFrom && moment(props.content.fieldShowFrom.value).isBefore()) {
    showModule = true;
  }

  // Check if "Show Till" Value is passed
  if (props.content.fieldShowTill && moment().isAfter(props.content.fieldShowTill.value)) {
    showModule = false;
  }
  if (showModule) {
    return(
      <ContentBase content={[props.content.fieldPlannedContent]} />
    );
  }
};

export default ParagraphScheduledModule;