export const scrollOptions = {
  reserveScrollBarGap: true,
  allowTouchMove: el => {
  while (el && el !== document.body) {
    if (el.classList.contains('body-scroll-lock-ignore')) {
      return true;
    }

    el = el.parentElement;
    }
  }
}