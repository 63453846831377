import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import NewsletterRegistration from "./newsletter-registration";
import SocialMediaInfo from "../social-media-info/social-media-info";

const ParagraphNewsletteranmeldung = ({ content }) => {
  const showSocialMedia = content.fieldSocialMediaLinks && content.fieldSocialMediaTitle;

  const sectionClassNames = classNames({
    "paragraph paragraph-newsletter-register": true,
    "with-social-media": showSocialMedia,
  }),
    socialMediaClassNames = classNames({
    "social-media-col col-16 col-md-12 offset-md-2 offset-lg-0 col-lg-8": true,
  }),
    newsletterClassNames = classNames({
    "newsletter-col col-16 col-lg-8": true,
    "offset-lg-4": !showSocialMedia,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-xxl-12 offset-xxl-2">
            <div className="row">
              {showSocialMedia &&
                <div className={socialMediaClassNames}>
                  <SocialMediaInfo content={content}/>
                </div>
              }
              <div className={newsletterClassNames}>
                <NewsletterRegistration content={content} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ParagraphNewsletteranmeldung.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldText: PropTypes.shape({
      __typename: PropTypes.string,
      value: PropTypes.string,
      processed: PropTypes.string,
    }),
  }),
};

export default ParagraphNewsletteranmeldung;
