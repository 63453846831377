import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

class QuoteContent extends Component {
  render() {
    const { fieldImage = null, fieldZitat, fieldQuelle } = this.props.content;

    const sectionClassNames = classNames({
      "quote-component": true,
      "with-image": fieldImage !== null,
      "row": !this.props.inCarousel,
      //"extra-long-text": fieldZitat.length >= 270,
      //"long-text": fieldZitat.length >= 180 && fieldZitat.length < 270,
      //"short-text": fieldZitat.length < 180,
      "short-text": true,
    });

    return (
      <article className={sectionClassNames}>
        {fieldImage !== null && (
          <div
            className={classNames({
              "image col-10 offset-2 col-md-4 offset-lg-2" : true,
              "offset-lg-2": this.props.inCarousel,
              "offset-3 offset-md-0": !this.props.inCarousel
            })}
          >
            <ErrorBoundary>
              <Image data={fieldImage.entity.fieldMediaImage} />
            </ErrorBoundary>
          </div>
        )}

        <div
          className={classNames({
            "text-wrapper": true,
            "col-14": this.props.inCarousel,
            "col-16": !this.props.inCarousel,
            "col-md-12 col-lg-8": fieldImage !== null,
            "col-md-12 offset-md-2": fieldImage === null,
          })}
        >
          <div className="quote-wrapper">
            <blockquote>
              {fieldZitat}
              <cite>【{fieldQuelle}】</cite>
            </blockquote>
          </div>
        </div>
      </article>
    );
  }
}

QuoteContent.propTypes = {
  inCarousel: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.object,
      }),
    }),
    fieldZitat: PropTypes.string.isRequired,
    fieldQuelle: PropTypes.string.isRequired,
  }),
};

QuoteContent.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default QuoteContent;
