import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TeaserKvCategoryChildren from "./teaser-kv-category-children";
import {encodeUrl} from "../../../lib/encode-url";
import useOverviewContext from "../../content-base/paragraphs/artist-overview/store/use-overview-context";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const TeaserKVCategory = (props) => {
  // Get relevant values from the OverviewContext.
  const { selectCategory } = useOverviewContext();
  const parent = props.content;

  return (
    <article className="node node-teaser teaser-artist-taxonomy">
      <div className="container-fluid">
        <div className="row">
          <div className="title-wrapper col-16">
            <h2>
              {parent.name}
            </h2>
          </div>
        </div>
      </div>

      {parent.children &&
        <div className="container-fluid">
          <div className="row artist-overview-teaser-wrapper">
            {Array.from(parent.children).map((child, childIndex) =>
              <div
                className="col-16 col-lg-8"
                key={childIndex}
                onClick={() => selectCategory(child, parent)}>
                <TeaserKvCategoryChildren
                  content={child}
                />
              </div>
            )}
          </div>
        </div>
      }
    </article>
  );
}
export const teaserKVCategoryPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAt: PropTypes.shape({
    value: PropTypes.number,
    publishedAtOrNow: PropTypes.number,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserKVCategory.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserKVCategoryPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired
};

export default connect(mapStateToProps)(withRouter(TeaserKVCategory));
