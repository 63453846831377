import React, {useState, useEffect} from "react";
import { graphql } from "@apollo/client/react/hoc";
import artistsByTaxonomyQuery from "artists-by-taxonomy-query.graphql";
import { connect } from "react-redux";
import TeaserArtist from "../../../teaser-base/artist/teaser-artist";
import groupBy from "lodash.groupby";
import {MultiTeaser} from "../teaser-overview-multi/multi-teaser";
import useOverviewContext from "./store/use-overview-context";
import OverviewProvider from "./store/overview-provider";
import PropTypes from "prop-types";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite,
});


const ArtistOverviewArtists = (props) => {

  const artists = props.artists.nodeQuery?.entities;
  const [groupedArtists, setGroupedArtists] = useState([]);

  useEffect(() =>   {
    if (artists) {
      // All Artists grouped by their taxonomy @todo: can this force errors, because there could be spaces and special characters?
      let groupedArtists = false;

      if (props.artistType === "artist") {
        groupedArtists = groupBy(artists, artist => artist.fieldArtistCategory && artist.fieldArtistCategory[0] && artist.fieldArtistCategory[0].entity?.name);
      } else {
        groupedArtists = groupBy(artists, artist => artist.fieldEnsembleCategory && artist.fieldEnsembleCategory[0] && artist.fieldEnsembleCategory[0].entity?.name);
      }

      // Array to push objects consisting of Taxonomy info and Artists
      let groupedArtistsWithName = [];

      Object.keys(groupedArtists).map((keyName) => {
        if (keyName !== "undefined") {
          groupedArtistsWithName.push({
            taxonomyName: keyName,
            artists: groupedArtists[keyName]
          });
        }
      });
      setGroupedArtists(groupedArtistsWithName);
    }
  }, [artists]);

  return(
    <section className="paragraph paragraph-artist-overview">
      <div className="container-fluid">
        <div className="row">
          <div className="main-category-wrapper col-16">
            <h2>
              {/*Render Parent Name and Selected Category Name*/}
              {props?.filterByTaxonomy?.parent && props?.filterByTaxonomy?.parent[0]?.entity.name} / {props.filterByTaxonomy?.name}
            </h2>
          </div>
        </div>
      </div>
      {groupedArtists?.map((artistGroup, index) =>
        <div className="container-fluid" key={index}>
          <div className="row">
            <div className="title-wrapper col-16">
              <h2>
                {artistGroup.taxonomyName}
              </h2>
            </div>
          </div>
          <div className="row artist-overview-teaser-wrapper">
            {artistGroup.artists?.map((artist, artistIndex) => {

              const teaserContent = {
                image: artist.fieldBildWCaption?.entity.fieldMediaImage,
                title: artist.entityLabel,
                link: artist.path.alias,
                linkRouted: true,
                entityBundle: "artist"
              }

              return(
                <React.Fragment key={artistIndex}>
                  <MultiTeaser
                    content={teaserContent}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
};

const ParagraphArtistOverviewArtists = ({ taxonomies, artists, filterByTaxonomy, groupedTaxonomies, artistType }) => {
  return (
    <OverviewProvider>
      <ArtistOverviewArtists
        groupedTaxonomies={groupedTaxonomies}
        artists={artists}
        filterByTaxonomy={filterByTaxonomy}
        artistType={artistType}
      />
    </OverviewProvider>
  );
};
ParagraphArtistOverviewArtists.PropTypes = {
  content: PropTypes.shape({

  }),
}
export default connect(mapStateToProps)(
  graphql(artistsByTaxonomyQuery, {
    name: "artists",
    options: (props) => ({
      variables: {
        limit: 10000,
        // @todo: filter for parent Taxonomy?
        taxonomyId: props.filterByTaxonomy?.parent && props.groupedTaxonomies[props.filterByTaxonomy?.tid].map(tag => tag.tid.toString()),
        language: props.currentLanguage.toUpperCase(),
        kvEnabled: props?.type === "artist",
        ensembleEnabled: props?.type === "ensembles"
      },
    }),
  })(ParagraphArtistOverviewArtists)
);