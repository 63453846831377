import { configIDs } from "./../config";

const getConfigID = (page) => {
  switch (page) {
    case "gdf":
      return configIDs.gdf;
    case "kv":
      return configIDs.kv;
    case "wt":
      return configIDs.wt;
    case "general":
      return configIDs.general;
    case "hmdk":
      return configIDs.hmdk;
    case false:
      return configIDs.hmdk;
  }
}

export default getConfigID;