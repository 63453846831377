import React, { useState, useEffect } from "react";
import MultiTeaser from "./multi-teaser";
import PropTypes from "prop-types";

export const TeaserOverviewMulti = (props) => {
  return(
    <section className="paragraph paragraph-teaser-overview-multi">
      <div className="container">
        <div className="row">
          {props.content.fieldReferencedTeasers.map(
            (item, index) => (
              <React.Fragment key={index}>
                <MultiTeaser
                  content={item}
                  alternativeLabel={item.entity.fieldAlternativeLabel}
                />
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </section>
  );
};
TeaserOverviewMulti.PropTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldReferencedTeaser: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        entity: PropTypes.shape({
          __typename: PropTypes.string,
          fieldAlternativeLabel: PropTypes.string,
          fieldReferencedInternalConten: PropTypes.shape({
            __typename: PropTypes.string,
            entity: PropTypes.shape({
              __typename: PropTypes.string,
              entityBundle: PropTypes.string,
              entityId: PropTypes.string,
              entityUrl: PropTypes.shape({
                __typename: PropTypes.string,
                path: PropTypes.string,
              }),
              title: PropTypes.string,
              path: PropTypes.shape({
                __typename: PropTypes.string,
                alias: PropTypes.string
              }),
              fieldTeaserbild: PropTypes.shape({
                __typename: PropTypes.string,
                entity: PropTypes.shape({
                  __typename: PropTypes.string,
                  fieldMediaImage: PropTypes.shape({
                    alt: PropTypes.string,
                    title: PropTypes.string,
                    style: PropTypes.shape({
                      url: PropTypes.string,
                    }),
                  }),
                })
              })
            }),
          }),
        }),
      }),
    ),
  }),
}
export default TeaserOverviewMulti;