import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Image from "../../image/image";
import PersonName from "./../../../person/person-name";
import PersonImage from "./../../../person/person-image";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";
import PersonClassification from "../../../person/person-classification";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo Rename. This is the person teaser with modal on click.
 * @todo Implement viewMode property for all invocations.
 */
class TeaserPersonOverview extends Component {

  render() {
    return (
      <>
        <article
          className="node node-teaser teaser-person teaser-person-overview"
          data-testid="teaser-person"
        >
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          <a className="flex-wrap" href={this.props.item.entityUrl.path}>

            <PersonImage
              content={this.props.item}
              styleSquare={true}
            />

            <div className="text-wrapper">
              <PersonClassification content={this.props.item}/>
              <h4 className="headline-xs">
                <PersonName content={this.props.item}/>
              </h4>
            </div>
          </a>
        </article>
      </>
    );
  }
}

TeaserPersonOverview.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPersonOverview));
